import React from "react";
import FeedMenu from "./FeedMenu";
import ContentMood from "./ContentMood";
import Languages from "./Languages";
import NormalDropdown from "./NormalDropdown";
const NavLinks = () => {
  return (
    <div className=" w-full  grid grid-cols-4 grid-rows-1 gap-x-0.5 items-center">
      <FeedMenu />
      <NormalDropdown />
      <ContentMood />
      <Languages />
    </div>
  );
};

export default NavLinks;
