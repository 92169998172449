import axios from "axios";
import { prepareRequestBody } from "./exportedFunctions";
import { filter } from "lodash";
export const getAutoCompleteSuggestions = async (
  searchWord,
  abortControllerRef
) => {
  try {
    abortControllerRef.current = new AbortController();
    const res = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/suggestions`,
      params: {
        query: `${searchWord}`,
        limit: 20,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      },
      signal: abortControllerRef.current.signal,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const searchFeedItems = async (
  { modifiedRequestBodyData, selectedData, abortControllerRef },
  rejectWithValue
) => {
  const requestBody = prepareRequestBody(modifiedRequestBodyData, selectedData);
  const isFreeTextFunc = () => {
    const isFreeTextData = requestBody?.isFreeText;
    delete requestBody.isFreeText;
    return isFreeTextData;
  };
  try {
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/relevance-card`,
      params: { accessToken: process.env.REACT_APP_ACCESS_TOKEN },
      headers: {
        "Content-Type": "application/json",
      },
      data: requestBody,
      signal: abortControllerRef.current.signal,
    });
    const { articles } = results.data;
    return { articles, origin: isFreeTextFunc() ? "freeText" : "posts" };
  } catch (error) {
    const { message } = error;
    return rejectWithValue({
      message,
      origin: isFreeTextFunc() ? "freeText" : "posts",
    });
  }
};
export const searchCompanyInfo = async (
  modifiedRequestBodyData,
  companyInfoData,
  abortControllerRef,
  apiCompanyInfo,
  setApiCompanyInfo
) => {
  const requestBody = prepareRequestBody(
    modifiedRequestBodyData,
    companyInfoData
  );
  try {
    setApiCompanyInfo({
      ...apiCompanyInfo,
      loading: true,
      message: "",
      error: "",
    });
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/relevance-card`,
      params: { accessToken: process.env.REACT_APP_ACCESS_TOKEN },
      headers: {
        "Content-Type": "application/json",
      },
      data: requestBody,
      signal: abortControllerRef.current.signal,
    });
    const { articles } = results.data;
    setApiCompanyInfo({
      ...apiCompanyInfo,
      searchResults: articles,
      loading: false,
      message: `${articles.length ? "" : "No Results Found!"}`,
      error: "",
    });
  } catch (error) {
    const { message } = error;
    if (message !== "canceled") {
      setApiCompanyInfo({
        ...apiCompanyInfo,
        searchResults: [],
        loading: false,
        message: "",
        error: message,
      });
    }
  }
  return;
};
export const fetchLatestOrTrendingVisualitics = async (
  { sectionName, modifiedRequestBodyData, selectedData, abortControllerRef },
  rejectWithValue
) => {
  const selectedDataCopy = filter(
    selectedData,
    (obj) => obj.category !== "sources"
  );
  const requestBody = prepareRequestBody(
    modifiedRequestBodyData,
    selectedDataCopy
  );
  const url =
    sectionName === "latest"
      ? `${process.env.REACT_APP_BASE_URL}/frontend-api/mentions`
      : `${process.env.REACT_APP_BASE_URL}/frontend-api/mentions/trending`;

  try {
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "post",
      url,
      params: { accessToken: process.env.REACT_APP_ACCESS_TOKEN },
      headers: {
        "Content-Type": "application/json",
      },
      data: requestBody,
      signal: abortControllerRef.current.signal,
    });
    const dataValues =
      sectionName === "latest"
        ? results.data.entity_mentions
        : results.data.entity_trend_mentions;
    return dataValues;
  } catch (error) {
    const { message } = error;
    return rejectWithValue({ message });
  }
};

export const topicStatus = async () => {
  const allTopics = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/frontend-api/topic-status`,
    params: { accessToken: process.env.REACT_APP_ACCESS_TOKEN },
  });
  const data = allTopics.data.topics;
  return data;
};

export const revealHiddenSection = async (
  hiddenSectionData,
  unqId,
  hiddenTextRef
) => {
  try {
    const hiddenData = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/relevance/reveal-section`,
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      },

      data: hiddenSectionData,
    });
    const contents = hiddenData.data.end;
    hiddenTextRef.current[unqId].innerHTML = contents
      .map((content) => {
        const { flags, text } = content;
        if (flags) {
          return `<span class='bg-cream dark:text-black'>${text}</span>`;
        }
        return text;
      })
      .join("");
  } catch (error) {
    console.log(error);
  }
};

export const toggleSummary = async (sectionProps) => {
  const {
    buttonType,
    buttonText,
    sectionSummaryOrder,
    hiddenArticleKey,
    summary,
    setSummary,
    companyName,
  } = sectionProps;
  try {
    const summaryData = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/summary/document_section`,
      params: {
        hiddenKey: hiddenArticleKey,
        sectionOrder: sectionSummaryOrder,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      },
    });
    setSummary({
      ...summary,
      summaryTitle: companyName,
      summaryBody: summaryData.data,
      customButtonType: buttonType,
      customButtonText: buttonText,
    });
  } catch (error) {
    console.log(error);
  }
};

export const translatedTopicsFunc = async (language, abortControllerRef) => {
  try {
    abortControllerRef.current = new AbortController();
    const topics = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/topic-language`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        languageCode: `${language}`,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      },
      signal: abortControllerRef.current.signal,
    });
    return topics;
  } catch (error) {
    return { data: { topics: [] } };
  }
};

export const getPodCastsFromApi = async ({
  data,
  setData,
  abortControllerRef,
}) => {
  try {
    setData({ ...data, loading: true, error: "", message: "" });
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/podcasts`,
      params: { accessToken: process.env.REACT_APP_ACCESS_TOKEN },
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortControllerRef.current.signal,
    });
    setData({
      ...data,
      podcasts: results.data,
      loading: true,
      error: "",
      message: `${results.data.length ? "" : "No Results Found!"}`,
    });
  } catch (error) {
    const { message } = error;
    if (message !== "canceled") {
      setData({
        ...data,
        podcasts: [],
        loading: false,
        message: "",
        error: message,
      });
    }
  }
};
export const getPodCastEpisodeFromApi = async ({
  data,
  setData,
  abortControllerRef,
  podcastId,
}) => {
  try {
    setData({ ...data, loading: true, error: "", message: "" });
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/frontend-api/podcasts/episode/${podcastId}`,
      params: {
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        id: podcastId,
      },
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortControllerRef.current.signal,
    });
    setData({
      ...data,
      podcast: { ...results.data },
      loading: true,
      error: "",
      message: `${Object.keys(results.data).length ? "" : "No Results Found!"}`,
    });
  } catch (error) {
    const { message } = error;
    if (message !== "canceled") {
      setData({
        ...data,
        podcasts: [],
        loading: false,
        message: "",
        error: message,
      });
    }
  }
};
export const getEpisodeFromApi = async ({
  data,
  setData,
  abortControllerRef,
  transcriptUrl,
}) => {
  try {
    setData({ ...data, loading: true, error: "", message: "" });
    abortControllerRef.current = new AbortController();
    const results = await axios({
      method: "get",
      url: transcriptUrl,
      signal: abortControllerRef.current.signal,
    });
    setData({
      ...data,
      episode: { ...results.data },
      loading: true,
      error: "",
      message: `${Object.keys(results.data).length ? "" : "No Results Found!"}`,
    });
  } catch (error) {
    const { message } = error;
    if (message !== "canceled") {
      setData({
        ...data,
        episode: [],
        loading: false,
        message: "",
        error: message,
      });
    }
  }
};
