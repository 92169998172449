import { uniqueId } from "lodash";
import { getAutoCompleteSuggestions } from "./api";
const modifyCategoryName = (category) => {
  const categoryName = category.split("_")[0];
  let newCategoryName = "";
  switch (categoryName) {
    case "company":
      newCategoryName = "companies";
      break;
    case "sector":
      newCategoryName = "sectors";
      break;
    case "topic":
      newCategoryName = "topics";
      break;
    case "indices":
      newCategoryName = "indices";
      break;
    case "commodity":
      newCategoryName = "commodities";
      break;
    case "source":
      newCategoryName = "sources";
      break;
    case "keyword":
      newCategoryName = "keywords";
      break;
    default:
      break;
  }
  return newCategoryName;
};

export const handleSearchItems = async (searchWord, abortControllerRef) => {
  const dataObj = await getAutoCompleteSuggestions(
    searchWord,
    abortControllerRef
  );
  const newArrObj = [];
  Object.keys(dataObj).forEach((key) => {
    // transform obj to arr
    const categoryName = modifyCategoryName(key);
    dataObj[key].forEach((objVal) => {
      const newObj = {};
      const {
        label,
        id,
        topic_type = undefined,
        logo_url = undefined,
      } = objVal;
      // prevent 'quote' topic in the suggestions
      if (categoryName === "topics" && label === "Quote") {
        newObj.category = "";
        newObj.name = "";
        newObj.id = "";
        newObj.type = "";
        newObj.logo = "";
      } else {
        newObj.category = categoryName;
        newObj.name = label;
        newObj.id = id;
        newObj.type = topic_type ?? "";
        newObj.logo = logo_url ?? "";
      }
      newArrObj.push(newObj);
    });
  });
  return newArrObj;
};
export const filterSelectedData = (newData, selectedData) => {
  if (!newData.length) return;
  //add item to array
  const [incomingData] = newData;
  const dataCopy = [...selectedData];
  const filteredData = dataCopy.filter((data) => {
    if (incomingData?.customOption && data?.customOption) {
      return false;
    } else {
      return data.id !== incomingData.id;
    }
  });
  filteredData.push(incomingData);
  return filteredData;
};

export const prepareRequestBody = (requestBody, selectedData) => {
  const requestBodyCopy = { ...requestBody };
  selectedData.forEach((dataObj) => {
    let category = "";
    dataObj?.customOption
      ? (category = "customOption")
      : (category = dataObj.category);
    switch (category) {
      case "companies":
        requestBodyCopy.companyIds = [
          ...requestBodyCopy.companyIds,
          dataObj.id,
        ];
        break;
      case "sectors":
        requestBodyCopy.sectorCodes = [
          ...requestBodyCopy.sectorCodes,
          dataObj.id,
        ];
        break;
      case "topics":
        requestBodyCopy.topicIds = [...requestBodyCopy.topicIds, dataObj.id];
        break;
      case "indices":
        requestBodyCopy.indexIds = [...requestBodyCopy.indexIds, dataObj.id];
        break;
      case "sources":
        requestBodyCopy.sources = [...requestBodyCopy.sources, dataObj.id];
        break;
      case "customOption":
        requestBodyCopy.freeTextQuery = dataObj.name;
        requestBodyCopy.matchingTypes = ["NoneTopic"];
        // key to automatically render freeText card;
        requestBodyCopy.isFreeText = true;
        break;
      default:
        break;
    }
  });
  return requestBodyCopy;
};

export const aggregateType = (categoryName) => {
  let aggregateType = "";
  switch (categoryName) {
    case "companies":
      aggregateType = "company";
      break;
    case "sectors":
      aggregateType = "sector";
      break;
    case "topics":
      aggregateType = "topic";
      break;
    case "indices":
      aggregateType = "index";
      break;
    case "sources":
      aggregateType = "source";
      break;
    default:
      break;
  }
  return aggregateType;
};

export const updateAlert = (selected, temporaryAlert, setTemporaryAlert) => {
  if (!selected.length || temporaryAlert.length >= 3) return;
  const [incomingData] = selected;
  const filteredAlert = temporaryAlert.filter(
    (alert) => alert.id !== incomingData.id
  );
  setTemporaryAlert([...filteredAlert, incomingData]);
};

export const bodyText = (segmentsArray, companyId) => {
  return segmentsArray.map((segment) => {
    const { flags, text, company_id } = segment;
    const unqId = uniqueId("segment");
    if (flags) {
      return (
        <span key={unqId} className="bg-cream dark:text-black">
          {text}
        </span>
      );
    } else if (company_id === companyId) {
      return (
        <span key={unqId} className="font-bold">
          {text}
        </span>
      );
    } else {
      return <span key={unqId}>{text}</span>;
    }
  });
};
