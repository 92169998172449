import React, { memo } from "react";
import UnionWhite from "../assets/unionwhite.svg";
import UnionBlack from "../assets/unionblack.svg";
import UnionGreen from "../assets/uniongreen.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedData } from "../features/app/appSlice";
const CustomButton = (props) => {
  const { selectedData } = useSelector((store) => store.app);
  const dispatch = useDispatch();
  const { text, type, category, isClear, id } = props.customButtonProps;
  let color = "black";
  let backgroundColor = "white";
  let image = UnionBlack;

  if (category === "topics") {
    if (type === "EQUITY") {
     
      color = "#42A4D0";
      backgroundColor = "#FEF2E6";
      image = UnionWhite;
    } else {
      color = "#05AE51";
      backgroundColor = "#FEF2E6";
      image = UnionGreen;
    }

  }
  const removeItem = () => {
    if (isClear) {
      const dataCopy = [...selectedData];
      const filteredData = dataCopy.filter((data) => {
        if (data?.customOption && data.id === id) {
          return false;
        } else {
          return data.id !== id;
        }
      });
      dispatch(setSelectedData(filteredData));
    }
  };
  return (
    <button
      onClick={removeItem}
      style={{ color, backgroundColor }}
      className="py-1.5 px-2.5 border-2 border-sazerac my-px max-w-128 grid grid-rows-1 gap-x-1 grid-cols-customButton grid-flow-col items-center capitalize outline-none  rounded-full  font-bold text-customFontSix "
    >
      {isClear && (
        <div className="w-2.5 ">
          <img className="text-green-500 w-full" src={image} alt="Union" />
        </div>
      )}
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        {text}
      </span>
    </button>
  );
};

export default memo(CustomButton);
