import React, { useState, useEffect, useContext } from "react";
import VisualiticsCategories from "./VisualiticsCategories";
import VisualiticsLatestTrendingCategory from "./VisualiticsLatestTrendingCategory.";
import { visualiticsContext } from "../../common/context";
import VisualiticsContent from "./VisualiticsContent";
import { useNavigate } from "react-router-dom";
import VisualiticsTimeBtns from "./VisualiticsTimeBtns";

const Visualitics = () => {
  const [visualiticsBtns, setVisualiticsBtns] = useState({
    categoryBtn: "companies",
    trendyBtn: "latest",
    timeBtn: "today",
  });
  const {
    categoryBtn: category,
    trendyBtn: section,
    timeBtn: duration,
  } = visualiticsBtns;
  let navigate = useNavigate();
  const updateUrl = () => {
    navigate(`/visualitics/${category}/${section}/${duration}/`);
  };
  useEffect(() => updateUrl(), [visualiticsBtns]);
  return (
    <visualiticsContext.Provider
      value={{ visualiticsBtns, setVisualiticsBtns }}
    >
      <div className="w-full bg-inherit  px-1.5 pb-px">
        <div className="w-full grid grid-cols-visualiticsInnerNav grid-rows-1 grid-flow-col gap-x-1 items-center mb-1.5">
          <VisualiticsCategories />
          <VisualiticsLatestTrendingCategory />
        </div>
        <VisualiticsContent />
        <VisualiticsTimeBtns />
      </div>
    </visualiticsContext.Provider>
  );
};

export default Visualitics;
