import React, {
  useContext,
  useMemo,
  memo,
  useCallback,
  useState,
  useEffect,
} from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import { visualiticsContext } from "../../common/context";
import { filterSelectedData } from "../../common/exportedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedData } from "../../features/app/appSlice";
import VisualiticsSkeleton from "./VisualiticsSkeleton";
const Trending = ({ trendingProps }) => {
  const {
    visualiticsBtns: {
      categoryBtn: category,
      trendyBtn: section,
      timeBtn: duration,
    },
  } = useContext(visualiticsContext);
  const { searchResults, message, error, loading } = useSelector(
    (store) => store.visualitics
  );
  const { selectedData, translatedTopics } = useSelector((store) => store.app);
  const dispatch = useDispatch();
  const { topicStatus } = trendingProps;
  const [showMore, setShowMore] = useState(true);
  const [numberOfContent, setNumberOfContent] = useState(5);
  const navigate = useNavigate();
  const generateTrends = useCallback(() => {
    const trends = { positiveTrends: [], negativeTrends: [] };
    searchResults.forEach((result) => {
      const { entity_trend_percentage } = result;
      if (entity_trend_percentage >= 0) {
        trends.positiveTrends.push(result);
      } else {
        trends.negativeTrends.push(result);
      }
    });
    return trends;
  }, [searchResults]);
  const maxValueOfItems = (trendData, type) => {
    const value =
      trendData.length &&
      trendData.sort(
        (a, b) => b.entity_trend_percentage - a.entity_trend_percentage
      )[type].entity_trend_percentage;
    return value;
  };
  const positiveTrendsData = useCallback(() => {
    const { positiveTrends } = generateTrends();
    // type for determining greate value
    const type = 0;
    const maxValue = parseInt(maxValueOfItems(positiveTrends, type));
    return positiveTrends
      .sort((a, b) => b.entity_trend_percentage - a.entity_trend_percentage)
      .filter((trend) => trend.entity_trend_percentage > 0)
      .map((data) => {
        const dataPercent = parseInt(data?.entity_trend_percentage);
        const percentageWidth = (dataPercent / maxValue) * 100;
        let name = data.entity_name?.split("(")[0].trim();
        if (category === "topics") {
          name =
            translatedTopics.filter(
              (topic) => topic.topic_id === data.entity_id
            )[0]?.name ?? name;
        }
        return (
          <div
            onClick={() => toggleFeed(data)}
            key={data.entity_id}
            className="w-full pb-2 capitalize cursor-pointer "
          >
            <h2 className="mb-1 ml-1.5">{name}</h2>
            <div
              className="text-customFontTwo text-allPorts bg-onahau dark:bg-turquoise dark:text-black font-semibold text-center py-0.5 "
              style={{ width: `${Math.abs(percentageWidth)}%` }}
            >
              {dataPercent}%
            </div>
          </div>
        );
      });
  }, [searchResults]);
  const toggleFeed = async (selection) => {
    const topicsArray = await topicStatus;
    const { entity_id: id, entity_name: name } = selection;
    const getMatchedSelection =
      category === "topics"
        ? filter(topicsArray, (obj) => obj.topic_id === id)
        : [selection];
    const newData = getMatchedSelection.map((obj) => ({
      category,
      id,
      name,
      type: obj.topic_type ?? "",
    }));
    const filteredData = filterSelectedData(newData, selectedData);
    dispatch(setSelectedData(filteredData));
    navigate("/feeditems", { replace: true });
  };
  const negativeTrendsData = useCallback(() => {
    const { negativeTrends } = generateTrends();
    // type for determining greater value
    const type = negativeTrends.length - 1;
    const maxValue = parseInt(maxValueOfItems(negativeTrends, type));
    return negativeTrends
      .sort((a, b) => b.entity_trend_percentage - a.entity_trend_percentage)
      .filter((trend) => trend.entity_trend_percentage < 0)
      .map((data) => {
        const dataPercent = parseInt(data?.entity_trend_percentage);
        const percentageWidth = (dataPercent / maxValue) * 100;
        let name = data.entity_name?.split("(")[0].trim();
        if (category === "topics") {
          name =
            translatedTopics.filter(
              (topic) => topic.topic_id === data.entity_id
            )[0]?.name ?? name;
        }
        return (
          <div
            onClick={() => toggleFeed(data)}
            key={data.entity_id}
            className="w-full pb-2 capitalize cursor-pointer "
          >
            <h2 className="mb-1 mr-1.5 text-right">{name}</h2>
            <div
              className="text-customFontTwo text-out-orange bg-your-pink dark:bg-violet-red dark:text-white font-semibold text-center py-0.5 ml-auto"
              style={{ width: `${Math.abs(percentageWidth)}%` }}
            >
              {dataPercent}%
            </div>
          </div>
        );
      });
  }, [searchResults]);
  const { positiveTrends, negativeTrends } = useMemo(
    () => generateTrends(),
    [searchResults]
  );
  const toggleShowMore = () => {
    setShowMore(false);
    setNumberOfContent(10);
  };
  useEffect(() => {
    setNumberOfContent(5);
    setShowMore(true);
  }, [duration]);
  const positiveTrendsContent = positiveTrendsData();
  const negativeTrendsContent = negativeTrendsData();
  const results =
    positiveTrends.length > 0 || negativeTrends.length > 0 ? (
      <>
        <div className="w-full mb-4 text-customFontThree">
          <div className="w-full grid grid-cols-trending auto-rows-auto grid-flow-row  ">
            <div className="w-full row-start-2">
              {negativeTrendsContent.slice(0, numberOfContent)}
            </div>
            <div className="w-full row-span-2 border border-solitude" />
            <div className="w-full col-start-3 ">
              {positiveTrendsContent.slice(0, numberOfContent)}
            </div>
          </div>
        </div>
        <div className="w-full text-center">
          {(positiveTrendsContent.length > 5 ||
            positiveTrendsContent.length > 5) &&
          showMore ? (
            <button
              onClick={toggleShowMore}
              className="rounded-full  px-5 py-2.5 bg-onahau dark:bg-turquoise text-black-russian  text-customFontThree  font-semibold"
            >
              See More
            </button>
          ) : (
            <a
              href="https://www.texelio.com/demo"
              target="_blank"
              rel="noreferrer"
              className="rounded-full  px-5 py-2.5 bg-onahau dark:bg-turquoise text-black-russian  text-customFontThree  font-semibold"
            >
              Book a Demo
            </a>
          )}
        </div>
      </>
    ) : (
      <>
        {loading && (
          <div className="w-full">
            <VisualiticsSkeleton />
          </div>
        )}
        {message && (
          <p className="w-full text-customFontThree text-center">{message}</p>
        )}
        {error && (
          <p className="w-full text-customFontThree text-red-500 text-center">
            {error}
          </p>
        )}
      </>
    );

  return (
    <div className="w-full">
      <h1 className="w-full capitalize text-center text-customFontTen  ">
        {category} {section} {duration}
      </h1>
      <div className="w-full my-3">{results}</div>
    </div>
  );
};

export default memo(Trending);
