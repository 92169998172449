import React from "react";

const PageNotFound = () => {
  return (
    <div className="min-h-screen w-full bg-linen  p-2 text-black">
      <h1 className="text-center w-full text-lg ">
        Page Not Found. Make sure you provide parameters if required.
      </h1>
    </div>
  );
};

export default PageNotFound;
