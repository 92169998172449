import React, { memo, useMemo, useState, useEffect, useRef } from "react";
import { filter, isEmpty, uniqueId } from "lodash";
import { useSelector } from "react-redux";
import { topicStatus } from "../common/api";
import CustomButton from "./CustomButton";

const PodCastTextContent = ({ podCastTextContentProps }) => {
  const { translatedTopics } = useSelector((store) => store.app);
  const [allTopics, setAllTopics] = useState([]);
  const [topicsCollection, setTopicsCollection] = useState([]);
  const [buttonsReload, setButtonsReload] = useState(0);
  const textRef = useRef([]);
  const currentTimeCache = useRef([0, 0]);
  const { audioPlayerCopy, setIsPlaying, audioText } = podCastTextContentProps;

  const updateCurrentTime = (start) => {
    audioPlayerCopy.current.audio.current.currentTime = start;
    audioPlayerCopy.current.audio.current.play();
    setIsPlaying(true);
  };
  const getTopics = async () => {
    const topics = await topicStatus();
    setAllTopics([...allTopics, ...topics]);
  };
  const getTopicDetails = (topicId) => {
    const topicMatch = filter(allTopics, (obj) => obj.topic_id === topicId);
    return topicMatch[0];
  };
  const synchronizeText = () => {
    const currentTime = audioPlayerCopy.current.audio.current.currentTime;
    const allTexts = textRef.current;
    // prevent restyling if range of currentTime hasn't changed
    const [startTimeCache, endTimeCache] = currentTimeCache.current;
    if (currentTime >= startTimeCache && currentTime < endTimeCache) {
      return;
    }
    for (const { startTime, endTime, element, topicDiv = null } of allTexts) {
      if (currentTime >= startTime && currentTime < endTime) {
        element.classList.add("text-black");
        element.classList.remove("text-black/50");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        topicDiv?.scrollIntoView({ behavior: "smooth", block: "center" });
        // cache values
        currentTimeCache.current = [startTime, endTime];
        // force re-render topic buttons
        setButtonsReload(startTime);
      } else {
        element.classList.add("text-black/50");
        element.classList.remove("text-black");
      }
    }
  };
  useEffect(() => {
    getTopics();
  }, []);
  useEffect(() => {
    const audioPlayerElement = audioPlayerCopy.current?.audio?.current;
    audioPlayerElement?.addEventListener("timeupdate", synchronizeText);
    return () =>
      audioPlayerElement?.removeEventListener("timeupdate", synchronizeText);
  }, []);

  const textContent = useMemo(() => {
    return audioText.map((section) => {
      const unqId = uniqueId("podcast-text");
      const {
        sentence,
        start: startTime,
        end: endTime,
        topic_id: topicIdArray = [],
      } = section;
      if (topicIdArray.length > 0) {
        const topicsCollectionCopy = topicsCollection;
        topicsCollectionCopy.push({ startTime, endTime, topicIdArray });
        setTopicsCollection(topicsCollectionCopy);
      }
      return (
        <span
          ref={(span) =>
            textRef.current.push({ startTime, endTime, element: span })
          }
          className="whitespace-pre-wrap cursor-pointer"
          onClick={() => updateCurrentTime(startTime)}
          key={unqId}
        >
          {sentence}
        </span>
      );
    });
  }, []);

  const topicButtons = useMemo(() => {
    return topicsCollection.map((topicObj, outerIndex) => {
      const unqId = uniqueId("topicObj");
      const { startTime, endTime, topicIdArray } = topicObj;
      return (
        <div key={unqId} className="w-full">
          {topicIdArray.map((topicId, innerIndex) => {
            const button = uniqueId("button");
            const formattedStartTime = new Date(startTime * 1000)
              .toISOString()
              .substring(11, 19);
            const topicDetails = getTopicDetails(topicId);
            const buttonText =
              translatedTopics.filter((topic) => topic.topic_id === topicId)[0]
                ?.name ?? topicDetails?.topic_name;
            const buttonType = topicDetails?.topic_type;
            const [startTimeCache] = currentTimeCache.current;
            const isButtonPlaying =
              startTimeCache >= startTime && startTimeCache < endTime;
            return isEmpty(topicDetails) ? null : (
              <div key={button} className="w-full text-center">
                <p className="w-full font-bold">{formattedStartTime}</p>
                <div
                  className="w-full flex items-center justify-center"
                  onClick={() => updateCurrentTime(startTime)}
                >
                  <div
                    className={[
                      "rounded-full px-0.5 border",
                      isButtonPlaying ? "border-black" : "border-inherit",
                    ].join(" ")}
                  >
                    <CustomButton
                      customButtonProps={{
                        type: buttonType,
                        text: buttonText,
                        isClear: false,
                        category: "topics",
                      }}
                    />
                  </div>
                </div>
                {outerIndex !== topicsCollection.length - 1 && (
                  <div className="w-0.5 h-8 mx-auto bg-martini"></div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  }, [allTopics, buttonsReload]);

  return (
    <div className="w-full max-h-companyInfoHeight  my-2 grid grid-cols-earningsCallText items-start grid-rows-1 grid-flow-col gap-x-1 justify-start text-customFontEleven text-black">
      <div className="w-full h-full overflow-auto scrollbar-hide rounded-md bg-linen p-1">
        {textContent}
      </div>
      {topicButtons.length > 0 && (
        <div className="w-32 py-1 bg-inherit h-full overflow-auto scrollbar-hide  rounded-md">
          {topicButtons}
        </div>
      )}
    </div>
  );
};

export default memo(PodCastTextContent);
