import React, { useState, useEffect, useContext } from "react";
import SearchBar from "./SearchBar";
import NavLinks from "./NavLinks/NavLinks";
import SelectedItems from "./SelectedItems";
import LightModeIcon from "../assets/lightmode.svg";
import DarkModeIcon from "../assets/darkmode.svg";
import FinanceNavigation from "./FinanceNavigation";
import { contentContext } from "./../common/context";

const InternalNavBar = ({ internalNavBarProps }) => {
  const { dynamicWidth, appWidth, setDynamicWidth } =
    useContext(contentContext);
  const { abortControllerRef } = internalNavBarProps;
  const [isDark, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!isDark);
  };
  const toggleDynamicWidth = () => {
    if (appWidth < 768 || dynamicWidth === "420px") {
      setDynamicWidth("100%");
      return;
    } else {
      setDynamicWidth("420px");
    }
  };

  useEffect(() => {
    const body = document.body;
    if (isDark) {
      body.classList.add("dark");
    } else {
      body.classList.remove("dark");
    }
  }, [isDark]);
  return (
    <nav className="w-full sticky top-0 z-50   pt-9 text-customFontOne  text-black dark:text-white bg-inherit">
      <div className="w-full flex items-center justify-between px-2">
        <div className="">
          <span className="underline capitalize">
            <a href="https://www.texelio.com/" target="_blank" rel="noreferrer">
              learn more
            </a>
          </span>
        </div>
        <button onClick={toggleDynamicWidth}>Toggle View</button>
        <div className="w-10">
          {isDark ? (
            <button className="w-full" onClick={toggleDarkMode}>
              <img src={LightModeIcon} alt="Light mode" />
            </button>
          ) : (
            <button className="w-full" onClick={toggleDarkMode}>
              <img src={DarkModeIcon} alt="Dark mode" />
            </button>
          )}
        </div>
      </div>
      <div className="w-full  pt-1.5 px-1.5 pb-px ">
        <NavLinks />
        <FinanceNavigation />
        <SearchBar
          searchBarProps={{
            abortControllerRef,
          }}
        />
        <SelectedItems />
      </div>
    </nav>
  );
};

export default InternalNavBar;
