import React from "react";
import NormalCardSkeleton from "./NormalCardSkeleton";
import AlphaCardSkeleton from "./AlphaCardSkeleton";

const CardSkeleton = ({ cardSkeletonProps }) => {
  const { mode } = cardSkeletonProps;
  const skeletonCard =
    mode === "alpha" ? <AlphaCardSkeleton /> : <NormalCardSkeleton />;
  return <>{skeletonCard}</>;
};

export default CardSkeleton;
