import React, { useContext, useMemo } from "react";
import { visualiticsContext } from "../../common/context";
import Latest from "./Latest";
import { topicStatus } from "../../common/api";
import Trending from "./Trending";

const VisualiticsContent = () => {
  const {
    visualiticsBtns: { trendyBtn: section },
  } = useContext(visualiticsContext);
  const topics = useMemo(() => topicStatus(), []);
  return (
    <div className="w-full py-2 px-3 bg-linen dark:bg-rhino text-black dark:text-white font-semibold">
      {section === "latest" ? (
        <Latest latestProps={{ topicStatus: topics }} />
      ) : (
        <Trending trendingProps={{ topicStatus: topics }} />
      )}
    </div>
  );
};

export default VisualiticsContent;
