import React, { useRef, memo, useMemo, useEffect } from "react";
import { uniqueId } from "lodash";
import ReactTimeAgo from "react-time-ago";
import { revealHiddenSection } from "./../../common/api";
import { bodyText } from "../../common/exportedFunctions";

const FreeTextPost = ({ articleContent }) => {
  const {
    primary_concept: { name: companyName },
    created_ts: releaseDate,
    source_name: sourceName,
    hidden_source_url: hiddenSourceUrl,
    sections,
  } = articleContent;
  const hiddenTextRef = useRef([]);

  const sectionsArray = useMemo(() => {
    return sections.map((section) => {
      const unqId = uniqueId("freeText");
      return (
        <div key={unqId} className="w-full  my-2.5 text-customFontEleven">
          {section.end_hidden ? (
            <p
              onClick={() =>
                revealHiddenSection(
                  section.hidden_text_data,
                  unqId,
                  hiddenTextRef
                )
              }
              className="w-full cursor-pointer"
            >
              {bodyText(section.segments)}
              <span ref={(span) => (hiddenTextRef.current[unqId] = span)} />
            </p>
          ) : (
            <p>{bodyText(section.segments)}</p>
          )}
        </div>
      );
    });
  }, [articleContent]);
  useEffect(() => {
    //set default ... for each span
    Object.values(hiddenTextRef.current).forEach((span) => {
      if (span)
        span.innerHTML = `<span class='text-gray-600'>... (see more)</span>`;
    });
  }, []);
  return (
    <div className="w-full px-3 py-4 text-customFontTwo text-black dark:text-white mb-5 bg-linen dark:bg-rhino ">
      <h1 className="font-bold capitalize">{companyName}</h1>
      {sectionsArray}
      <div className="flex items-center justify-between w-full  text-customFontNine capitalize ">
        <span className="mr-1">
          <ReactTimeAgo
            date={releaseDate * 1000}
            locale="en-US"
            timeStyle="round-minute"
          />
        </span>
        <span className="mr-1">|</span>
        <span className="mr-1">{sourceName}</span>
        <span className="mr-1">|</span>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/frontend-api/relevance/follow-source-url?accessToken=${process.env.REACT_APP_ACCESS_TOKEN}&data=${hiddenSourceUrl}`}
          target="_blank"
          rel="noreferrer"
          className="underline  cursor-pointer"
        >
          Source
        </a>
      </div>
    </div>
  );
};

export default memo(FreeTextPost);
