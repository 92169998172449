import React, { useEffect, memo } from "react";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import AudioPlayer from "react-h5-audio-player";
import "../Css/audioPlayer.css";
const PodCastAudioPlayer = React.forwardRef(
  ({ AudioPlayerComponentProps }, audioPlayerRef) => {
    const { isPlaying, setIsPlaying, audioUrl } = AudioPlayerComponentProps;
    const togglePlayPause = () => {
      setIsPlaying(!isPlaying);
    };
    const handleRewindForwardPlay = (direction) => {
      direction === "rewind"
        ? audioPlayerRef.current.handleClickRewind()
        : audioPlayerRef.current.handleClickForward();
    };
    const playPauseAudio = () => {
      if (isPlaying) {
        audioPlayerRef.current.audio.current.play();
      } else {
        audioPlayerRef.current.audio.current.pause();
      }
    };
    const audioEndedPlaying = () => {
      setIsPlaying(false);
    };
    useEffect(() => {
      playPauseAudio();
      return;
    }, [isPlaying]);
    const customControlsSection = [
      <div className="w-full flex items-center justify-center">
        <button
          onClick={() => handleRewindForwardPlay("rewind")}
          className="flex items-center justify-center font-bold"
        >
          <p className="relative left-6 top-0.5 text-xs">15</p>
          <GrRotateLeft className="text-4xl" />
        </button>
        {isPlaying ? (
          <button onClick={togglePlayPause}>
            <BsPauseFill className="text-4xl" />
          </button>
        ) : (
          <button onClick={togglePlayPause}>
            <BsPlayFill className="text-4xl" />
          </button>
        )}
        <button
          onClick={() => handleRewindForwardPlay("forward")}
          className="flex items-center justify-center font-bold"
        >
          <GrRotateRight className="text-4xl" />
          <p className="relative -left-6 top-0.5 text-xs">15</p>
        </button>
      </div>,
    ];
    return (
      <div className="w-full">
        <AudioPlayer
          ref={(element) => (audioPlayerRef.current = element)}
          className="bg-inherit shadow-none text-customFontEleven font-bold text-black"
          src={audioUrl}
          progressJumpSteps={{ backward: 15000, forward: 15000 }}
          customControlsSection={customControlsSection}
          customAdditionalControls={[]}
          onEnded={audioEndedPlaying}
        />
      </div>
    );
  }
);

export default memo(PodCastAudioPlayer);
