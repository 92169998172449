import React, { useRef, useState } from "react";
import AudioPlayerComponent from "./AudioPlayerComponent";
import EarningsCallTextContent from "./EarningsCallTextContent";
import {
  appleEarningsCall,
  carnegieEarningsCall,
} from "./../common/exportedData";
const Earnings = ({ earningsProps }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef();
  const name = earningsProps?.companyInfoData[0]?.name;
  let renderedContent = <h1>Earnings</h1>;
  if (name === "Apple Inc (AAPL)" || name === "Carnegie"|| name === "podcast 1") {
    renderedContent = (
      <div className="bg-inherit w-full py-1 text-black">
        <div className="w-full bg-linen dark:bg-rhino py-1 px-2">
          <h1 className=" w-full dark:text-white truncate text-center capitalize text-customFontFourteen font-semibold my-2 ">
            {name === "Carnegie"
              ? "Carnegie Private Banking Pod - Då ökar vi aktievikten igen"
              : "Apple Inc (AAPL)- Earnings Call"}
          </h1>
          <AudioPlayerComponent
            AudioPlayerComponentProps={{
              isPlaying,
              setIsPlaying,
              audioUrl:
                name === "Apple Inc (AAPL)"
                  ? "https://cdn.texelio.com/earnings_calls/apple_2022_q3_earnings_call_v2.mp3"
                  : "https://cdn.texelio.com/podcast/peter_jonas.mp3",
            }}
            ref={audioPlayerRef}
          />
        </div>
        <EarningsCallTextContent
          earningsCallTextContentProps={{
            audioPlayerCopy: audioPlayerRef,
            setIsPlaying,
            audioText:
              name === "Apple Inc (AAPL)"
                ? appleEarningsCall
                : carnegieEarningsCall,
          }}
        />
      </div>
    );
  }
  return renderedContent;
};

export default Earnings;
