import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "all",
  mood: "all",
  mode: "normal",
  topics: "all",
  media: "all",
  section: "all",
  selectedData: [],
  translatedTopics: [],
};
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedData: (state, { payload }) => {
      state.selectedData = payload;
    },
    setTranslatedTopics: (state, { payload }) => {
      state.translatedTopics = payload;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setMood: (state, { payload }) => {
      state.mood = payload;
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setTopics: (state, { payload }) => {
      state.topics = payload;
    },
    setMedia: (state, { payload }) => {
      state.media = payload;
    },
    setSection: (state, { payload }) => {
      state.section = payload;
    },
  },
});
export const {
  setLanguage,
  setMood,
  setMode,
  setTopics,
  setMedia,
  setSection,
  setSelectedData,
  setTranslatedTopics,
} = appSlice.actions;
export default appSlice.reducer;
