import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Feed from "./components/Feed";
import FeedItems from "./components/FeedItems/FeedItems";
import Visualitics from "./components/Visualitics/Visualitics";
import useMeasure from "react-use-measure";
import { contentContext } from "./common/context";
import { translatedTopicsFunc } from "./common/api";
import { useDispatch, useSelector } from "react-redux";
import { setTranslatedTopics } from "./features/app/appSlice";
import CarnegiePodcasts from "./components/PodCasts";
import PodCasts from "./components/PodCasts";
import PodCast from "./components/PodCast";
import AudioFile from "./components/AudioFile";
import TranscriptPlayer from "./components/TranscriptPlayer";
import PageNotFound from './components/PageNotFound';
function App() {
  const { language } = useSelector((store) => store.app);
  const [dynamicWidth, setDynamicWidth] = useState();
  const abortControllerRef = useRef(null);
  const [ref, { width }] = useMeasure();
  const firstMount = useRef(0);
  const dispatch = useDispatch();

  const getTranslatedTopics = async () => {
    const topicsData = await translatedTopicsFunc(language, abortControllerRef);
    const { topics } = topicsData.data;
    dispatch(setTranslatedTopics(topics));
  };

  useEffect(() => {
    if (!firstMount.current && width) {
      if (width < 768) {
        setDynamicWidth("100%");
      } else {
        setDynamicWidth("420px");
      }
      firstMount.current++;
    }
  }, [width]);

  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getTranslatedTopics();
    return () => abortControllerRefCopy.current?.abort();
  }, [language]);

  return (
    <Router>
      <contentContext.Provider
        value={{
          dynamicWidth,
          appWidth: width,
          setDynamicWidth,
          width,
        }}
      >
        <div ref={ref} className="w-full bg-black font-poppins pb-2.5">
          <Routes>
            <Route path="/" element={<Feed />}>
              <Route index element={<FeedItems />} />
              <Route path="/feeditems/*" element={<FeedItems />} />
              <Route path="/visualitics/*" element={<Visualitics />} />
            </Route>
            <Route path="/podcasts" element={<PodCasts />} />
            <Route path="/podcasts/:podcastId" element={<PodCast />} />
            <Route path="audiofile" element={<AudioFile />} />
            <Route
              path="/transcriptplayer/:transcribe"
              element={<TranscriptPlayer />}
            />
             <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </contentContext.Provider>
    </Router>
  );
}

export default App;
