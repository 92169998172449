import React, { useMemo, useState, useEffect } from "react";
import Post from "./Post/Post";
import FreeTextPost from "./FreeTextPost";
import { groupBy, sortBy, uniqueId } from "lodash";
import AlphaContent from "./AlphaMode/AlphaContent";
import { useSelector } from "react-redux";
import CardSkeleton from "./../CardSkeleton";
const FeedItems = () => {
  const [numberOfContent, setNumberOfContent] = useState(15);
  const { mode } = useSelector((store) => store.app);
  const { searchResults, loading, postOrigin, error, message } = useSelector(
    (store) => store.feed
  );
  const getAlphaContent = () => {
    const groupedCompanies = groupBy(
      searchResults,
      ({ primary_concept: { id } }) => id
    );
    const alphaContents = Object.keys(groupedCompanies).map((stockId) => (
      <AlphaContent
        key={stockId}
        alphaComponentProps={{
          stockInfo: groupedCompanies[stockId],
          id: stockId,
        }}
      />
    ));
    return alphaContents;
  };
  const content = useMemo(() => {
    if (postOrigin === "posts") {
      if (mode === "alpha") return getAlphaContent();
      return sortBy(searchResults, ({ primary_concept: { name } }) => name).map(
        (result) => {
          const unqId = uniqueId("result");
          return <Post key={unqId} articleContent={result} />;
        }
      );
    } else {
      return sortBy(searchResults, ({ primary_concept: { name } }) => name).map(
        (result) => {
          const unqId = uniqueId("result");
          return <FreeTextPost key={unqId} articleContent={result} />;
        }
      );
    }
  }, [searchResults, mode]);
  const toggleLoadMore = () => {
    setNumberOfContent((prevNumber) => prevNumber + 15);
  };
  useEffect(() => {
    setNumberOfContent(15);
  }, [searchResults]);
  const results = content?.length ? (
    <div className="w-full bg-inherit">
      <div className="w-full mb-4">{content.slice(0, numberOfContent)}</div>
      <div className="w-full text-center">
        {content.length > 15 && numberOfContent < content.length ? (
          <button
            onClick={toggleLoadMore}
            className="rounded-full  px-5 py-2.5 mb-2 bg-pattern-blue dark:bg-turquoise text-customFontThree text-allPorts dark:text-black font-semibold"
          >
            Load More
          </button>
        ) : (
          <button className="rounded-full  px-5 py-2.5 mb-2 bg-pattern-blue dark:bg-turquoise text-customFontThree text-allPorts dark:text-black font-semibold">
            <a
              href="https://www.texelio.com/demo"
              target="_blank"
              rel="noreferrer"
            >
              Book a Demo
            </a>
          </button>
        )}
      </div>
    </div>
  ) : (
    <div className="w-full bg-inherit">
      {loading && <CardSkeleton cardSkeletonProps={{ mode }} />}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );
  return results;
};

export default FeedItems;
