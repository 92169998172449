import React from "react";
import ReactTimeAgo from "react-time-ago";
import { animated, useSpring } from "@react-spring/web";
const CompanyHeader = ({ companyInfo }) => {
  const {
    imageUrl,
    releaseDate,
    sourceName,
    companyName,
    companyId,
    companiesArray,
    hiddenSourceUrl,
    headline,
  } = companyInfo;

  const exchangeSymbol = companiesArray.filter(
    (company) => company.id === companyId
  )[0]?.exchange_symbol;
  const ticker = exchangeSymbol ? exchangeSymbol.split(":")[1] : null;
  // animate background image
  const styles = useSpring({
    from: { scale: 1.5 },
    to: { scale: 1 },
    delay: 1000,
    config: { duration: 15000 },
  });
  return (
    <div className="w-full">
      {imageUrl && (
        <div className="w-full h-149px overflow-hidden">
          <animated.div
            className="w-full h-full bg-black bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),url(${imageUrl})`,
              ...styles,
            }}
          ></animated.div>
        </div>
      )}
      <div className="w-full grid grid-cols-companyHeaderCol grid-rows-1 grid-flow-col gap-x-2 pt-2 px-2">
        <div className="w-full overflow-hidden">
          <h1 className="text-sm capitalize mb-1 font-bold truncate">
            {companyName} {ticker && `(${ticker})`}
          </h1>
          <h2 className=" text-customFontTwentyTwo font-medium line-clamp-3 ">
            {headline}
          </h2>
        </div>
        <div className="justify-self-end">
          <div className="font-semibold text-customFontSix text-end whitespace-nowrap">
            <ReactTimeAgo
              date={releaseDate * 1000}
              locale="en-US"
              timeStyle="round-minute"
            />
          </div>
          <div className="text-end">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/frontend-api/relevance/follow-source-url?accessToken=${process.env.REACT_APP_ACCESS_TOKEN}&data=${hiddenSourceUrl}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 text-customFontSix font-medium cursor-pointer underline "
            >
              {sourceName}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
