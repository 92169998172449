import React, { useRef, useState, useEffect } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getPodCastEpisodeFromApi } from "../common/api";
import PodCastAudioPlayer from "./PodCastAudioPlayer";
import PodCastTextContent from "./PodCastTextContent";
import BeatLoader from "react-spinners/BeatLoader";
import { appleEarningsCall } from "./../common/exportedData";
const PodCast = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { podcastId } = useParams();
  const abortControllerRef = useRef(null);
  const [data, setData] = useState({
    podcast: {},
    loading: false,
    message: "",
    error: "",
  });
  const { podcast, loading, message, error } = data;
  const getEpisode = () => {
    // hard coded for apple earnings-call

    if (podcastId === "apple-earnings-call") {
      setData({
        ...data,
        podcast: {
          id: "apple-earnings-call",
          title: "Apple Earnings Call",
          audio_url:
            "https://cdn.texelio.com/earnings_calls/apple_2022_q3_earnings_call_v2.mp3",
          transcript: appleEarningsCall,
        },
        loading: false,
        error: "",
        message: "",
      });
    } else {
      getPodCastEpisodeFromApi({
        data,
        setData,
        abortControllerRef,
        podcastId,
      });
    }
  };
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getEpisode();
    return () => abortControllerRefCopy.current?.abort();
  }, [podcastId]);
  const audioPlayerRef = useRef();
  const content = useMemo(() => {
    return Object.keys(podcast);
  }, [podcast]);
  const results = content?.length ? (
    <>
      <div className="sticky top-0 bg-linen w-full md:max-w-md mx-auto py-1 px-2">
        <h1 className=" w-full truncate text-center capitalize text-customFontFourteen font-semibold my-2 underline">
          {podcast.title}
        </h1>
        <PodCastAudioPlayer
          AudioPlayerComponentProps={{
            isPlaying,
            setIsPlaying,
            audioUrl: podcast.audio_url,
          }}
          ref={audioPlayerRef}
        />
      </div>
      <div className="w-full md:max-w-md mx-auto py-1">
        <PodCastTextContent
          podCastTextContentProps={{
            audioPlayerCopy: audioPlayerRef,
            setIsPlaying,
            audioText: podcast.transcript,
          }}
        />
      </div>
    </>
  ) : (
    <div className="w-full bg-inherit">
      {loading && (
        <div className="w-full text-center">
          <BeatLoader color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );

  return (
    <div className="h-screen w-full bg-linen  p-2 text-black">
      {results}
    </div>
  );
};

export default PodCast;
