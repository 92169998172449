import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchFeedItems } from "../../common/api";

const initialState = {
  searchResults: [],
  postOrigin: "posts",
  loading: false,
  message: "",
  error: "",
};
export const getFeedData = createAsyncThunk(
  "app/fetchFeedData",
  (feedParams, { rejectWithValue }) => {
    return searchFeedItems(feedParams, rejectWithValue);
  }
);
const feedSlice = createSlice({
  name: "feed",
  initialState,
  extraReducers: {
    [getFeedData.pending]: (state) => {
      state.searchResults = [];
      state.loading = true;
      state.message = "";
      state.error = "";
    },
    [getFeedData.fulfilled]: (state, action) => {
      const { articles, origin } = action.payload;
      state.loading = false;
      state.searchResults = articles;
      state.postOrigin = origin;
      state.message = `${articles.length ? "" : "No Results Found!"}`;
      state.error = "";
    },
    [getFeedData.rejected]: (state, { payload }) => {
      const { message: errorMessage, origin } = payload;
      if (errorMessage !== "canceled") {
        state.searchResults = [];
        state.postOrigin = origin;
        state.loading = false;
        state.message = "";
        state.error = errorMessage;
      }
    },
  },
});
export default feedSlice.reducer;
