import React, { useState, useRef } from "react";
import PodCastAudioPlayer from "./PodCastAudioPlayer";
import { BankgeheimnisText } from "./../common/exportedData";
import AudioText from "./AudioText";

const AudioFile = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef();
  return (
    <div className="bg-linen w-full min-h-screen">
      <div className="sticky top-0 bg-linen w-full md:max-w-md mx-auto py-1 px-2">
        <h1 className=" w-full truncate text-center capitalize text-customFontFourteen font-semibold my-2 underline">
          Bankgeheimnis
        </h1>
        <PodCastAudioPlayer
          AudioPlayerComponentProps={{
            isPlaying,
            setIsPlaying,
            audioUrl:
              "https://cdn.texelio.com/podcast/Bankgeheimnis_%20Nachhaltigkeit%20im%20Firmenkundengesch%C3%A4ft%20%E2%80%93%20Fokus%20Automobilsektor.mp3",
          }}
          ref={audioPlayerRef}
        />
      </div>
      <div className="w-full md:max-w-md mx-auto py-1">
        <AudioText
          AudioTextProps={{
            audioPlayerCopy: audioPlayerRef,
            setIsPlaying,
            audioText: BankgeheimnisText,
          }}
        />
      </div>
    </div>
  );
};

export default AudioFile;
