import React, { memo, useMemo, useContext, useState, useEffect } from "react";
import { visualiticsContext } from "../../common/context";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { filter } from "lodash";
import { filterSelectedData } from "../../common/exportedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedData } from "../../features/app/appSlice";
import LatestSkeleton from "./VisualiticsSkeleton";
import VisualiticsSkeleton from "./VisualiticsSkeleton";
const Latest = ({ latestProps }) => {
  const {
    visualiticsBtns: {
      categoryBtn: category,
      trendyBtn: section,
      timeBtn: duration,
    },
  } = useContext(visualiticsContext);

  const { searchResults, message, error, loading } = useSelector(
    (store) => store.visualitics
  );
  const { selectedData, translatedTopics } = useSelector((store) => store.app);
  const dispatch = useDispatch();
  const { topicStatus } = latestProps;
  const [showMore, setShowMore] = useState(true);
  const [numberOfContent, setNumberOfContent] = useState(5);
  const navigate = useNavigate();
  const toggleFeed = async (selection) => {
    const topicsArray = await topicStatus;
    const { entity_id: id, entity_name: name } = selection;
    const getMatchedSelection =
      category === "topics"
        ? filter(topicsArray, (obj) => obj.topic_id === id)
        : [selection];
    const newData = getMatchedSelection.map((obj) => ({
      category,
      id,
      name,
      type: obj.topic_type ?? "",
    }));
    const filteredData = filterSelectedData(newData, selectedData);
    dispatch(setSelectedData(filteredData));
    navigate("/feeditems", { replace: true });
  };
  const maxValueOfItems = () => {
    const value =
      searchResults?.length &&
      searchResults.slice().sort((a, b) => b.entity_count - a.entity_count)[0]
        .entity_count;

    return value;
  };
  const latestItems = useMemo(() => {
    const maxVal = maxValueOfItems();
    return searchResults.map((result) => {
      const percentageValue = (result?.entity_count / maxVal) * 100;
      let name = result.entity_name?.split("(")[0].trim();
      if (category === "topics") {
        name =
          translatedTopics.filter(
            (topic) => topic.topic_id === result.entity_id
          )[0]?.name ?? name;
      }
      return (
        <div
          onClick={() => toggleFeed(result)}
          key={result.entity_id}
          className="w-full pb-1.5 capitalize cursor-pointer text-customFontThree"
        >
          <h2 className="mb-1">{name}</h2>
          <div
            className="text-customFontTwo text-allPorts bg-onahau dark:bg-turquoise dark:text-black  text-center py-0.5 "
            style={{ width: `${percentageValue}%` }}
          >
            {result.entity_count}
          </div>
        </div>
      );
    });
  }, [searchResults]);
  const toggleShowMore = () => {
    setShowMore(false);
    setNumberOfContent(10);
  };
  useEffect(() => {
    setNumberOfContent(5);
    setShowMore(true);
  }, [duration]);

  const results = latestItems?.length ? (
    <>
      <div className="w-full mb-4">{latestItems.slice(0, numberOfContent)}</div>
      <div className="w-full text-center">
        {latestItems.length > 5 && showMore ? (
          <button
            onClick={toggleShowMore}
            className="rounded-full  px-5 py-2.5 bg-onahau dark:bg-turquoise text-black-russian  text-customFontThree  font-semibold"
          >
            See More
          </button>
        ) : (
          <a
            href="https://www.texelio.com/demo"
            target="_blank"
            rel="noreferrer"
            className="rounded-full  px-5 py-2.5 bg-onahau dark:bg-turquoise text-black-russian  text-customFontThree  font-semibold"
          >
            Book a Demo
          </a>
        )}
      </div>
    </>
  ) : (
    <>
      {" "}
      {loading && (
        <div className="w-full">
          <VisualiticsSkeleton/>
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center">{message}</p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </>
  );

  return (
    <div className="w-full">
      <h1 className="w-full capitalize text-center text-customFontTen  ">
        {section} {category} {duration}
      </h1>
      <div className="w-full my-3">{results}</div>
    </div>
  );
};

export default memo(Latest);
