import React from "react";
import { uniqueId } from "lodash";
import Skeleton from "react-loading-skeleton";

const NormalCardSkeleton = () => {
  const cards = Array(5)
    .fill(0)
    .map((_) => {
      const unqId = uniqueId("normalskeleton");
      return (
        <div
          key={unqId}
          className="relative w-full pb-2.5 mb-5 bg-linen-shade-one dark:bg-rhino "
        >
          <Skeleton 
            className="w-full h-28 rounded-none bg-linen-shade-two dark:bg-rhino-shade-one "
          />
          <div className=" absolute top-0 w-full h-28 flex flex-col justify-between">
            <div className="w-full text-end  px-2">
              <Skeleton className="w-16 h-3 roundend-none " />
            </div>
            <div className="w-full px-2.5">
              <Skeleton className="w-9/12 h-4 mb-0.5 roundend-none" />
              <div className="w-full flex items-center justify-between">
                <Skeleton className="w-12 h-2.5 roundend-none" />
                <Skeleton className="w-12 h-2.5 roundend-none" />
              </div>
            </div>
          </div>
          <div className="w-full pt-2 px-2.5 ">
            <Skeleton  count={2.5} className="h-2.5 mb-0.5 " />
          </div>
        </div>
      );
    });
  return <div className="w-full">{cards}</div>;
};

export default NormalCardSkeleton;
