import React from "react";
import CustomSelectButton from "./../CustomSelectButton/CustomSelectButton";
const normalDropdown = [
  {
    label: "Normal",
    value: "normal",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "mode",
  },
  {
    label: "Alpha",
    value: "alpha",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "mode",
  },
];

const ContentMode = () => {
  return <CustomSelectButton options={normalDropdown} />;
};

export default ContentMode;
