import React, { useContext } from "react";
import { visualiticsContext } from "../../common/context";
import { setAfterTime } from "../../features/visualitics/visualiticsSlice";
import { useDispatch } from "react-redux";

const VisualiticsTimeBtns = () => {
  const { visualiticsBtns, setVisualiticsBtns } =
    useContext(visualiticsContext);
  const dispatch = useDispatch();
  const { timeBtn: selectedBtn } = visualiticsBtns;
  const timeButtons = [
    { name: "today", value: "24h" },
    { name: "last week", value: "7d" },
    { name: "last month", value: "30d" },
  ];
  const filterTime = (btnName) => {
    setVisualiticsBtns({ ...visualiticsBtns, timeBtn: btnName });
    let afterTime = "";
    switch (btnName) {
      case "today":
        afterTime = Date.now() / 1000 - 24 * 60 * 60;
        break;
      case "last week":
        afterTime = Date.now() / 1000 - 7 * 24 * 60 * 60;
        break;
      case "last month":
        afterTime = Date.now() / 1000 - 30 * 24 * 60 * 60;
        break;
      default:
        break;
    }
    dispatch(setAfterTime(afterTime));
  };
  return (
    <div className="w-10/12 mx-auto my-2 rounded-full p-1 bg-linen dark:bg-rhino  font-semibold text-black-russian text-customFontThree grid grid-cols-3 grid-rows-1 items-center ">
      {timeButtons.map((button) => (
        <button
          key={button.value}
          onClick={() => filterTime(button.name)}
          className={[
            "w-full rounded-full  py-1.5 px-9 text-center",
            selectedBtn === button.name
              ? " bg-onahau dark:bg-turquoise "
              : " dark:text-white",
          ].join(" ")}
        >
          {button.value}
        </button>
      ))}
    </div>
  );
};

export default VisualiticsTimeBtns;
