import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { fetchLatestOrTrendingVisualitics } from "../../common/api";

const today = Date.now() / 1000 - 24 * 60 * 60;
const initialState = {
  searchResults: [],
  afterTime: `${today}`,
  loading: false,
  message: "",
  error: "",
};
export const getVisualiticsData = createAsyncThunk(
  "app/fetchVisualiticsData",
  (visualiticsParams, { rejectWithValue }) => {
    return fetchLatestOrTrendingVisualitics(visualiticsParams, rejectWithValue);
  }
);
const visualiticsSlice = createSlice({
  name: "visualitics",
  initialState,
  reducers: {
    setAfterTime: (state, { payload }) => {
      state.afterTime = payload;
    },
  },
  extraReducers: {
    [getVisualiticsData.pending]: (state) => {
      state.searchResults = [];
      state.loading = true;
      state.message = "";
      state.error = "";
    },
    [getVisualiticsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.searchResults = payload;
      state.message = `${payload.length ? "" : "No Results Found!"}`;
      state.error = "";
    },
    [getVisualiticsData.rejected]: (state, { payload }) => {
      const { message: errorMessage } = payload;
      if (errorMessage !== "canceled") {
        state.searchResults = [];
        state.loading = false;
        state.message = "";
        state.error = errorMessage;
      }
    },
  },
});
export const { setAfterTime } = visualiticsSlice.actions;
export default visualiticsSlice.reducer;
