import { groupBy } from "lodash";
import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { searchCompanyInfo } from "../common/api";
import AlphaContent from "./FeedItems/AlphaMode/AlphaContent";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";
import AlphaCardSkeleton from "./AlphaCardSkeleton";

const CompanySpecificInfo = ({ companySpecificInfoProps }) => {
  const abortControllerRef = useRef(null);
  const { companyInfoData, apiCompanyInfo, setApiCompanyInfo } =
    companySpecificInfoProps;
  const { language,mood } = useSelector((store) => store.app);
  const [numberOfContent, setNumberOfContent] = useState(5);
  const updateContent = async () => {
    const languageArr = language === "all" ? [] : [`${language}`];
    const modifiedRequestBodyData = {
      topicIds: [],
      companyIds: [],
      indexIds: [],
      sectorCodes: [],
      languages: languageArr,
      sentiment: `${mood}`,
      limit: 25,
      freeTextQuery: "",
      includeSectionOrders: true,
      checkHasSummary: true,
      matchingTypes: [],
    };
    await searchCompanyInfo(
      modifiedRequestBodyData,
      companyInfoData,
      abortControllerRef,
      apiCompanyInfo,
      setApiCompanyInfo
    );
  };
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    updateContent();
    return () => abortControllerRefCopy.current?.abort();
  }, []);
  const { searchResults, error, message, loading } = apiCompanyInfo;
  const getAlphaContent = () => {
    const groupedCompanies = groupBy(
      searchResults,
      ({ primary_concept: { id } }) => id
    );
    const alphaContents = Object.keys(groupedCompanies).map((stockId) => (
      <AlphaContent
        key={stockId}
        alphaComponentProps={{
          stockInfo: groupedCompanies[stockId],
          id: stockId,
        }}
      />
    ));
    return alphaContents;
  };

  const content = useMemo(() => {
    return getAlphaContent();
  }, [searchResults]);
  const toggleLoadMore = () => {
    setNumberOfContent((prevNumber) => prevNumber + 5);
  };
  const results = content?.length ? (
    <div className="w-full bg-inherit">
      <div className="w-full mb-4">{content.slice(0, numberOfContent)}</div>
      <div className="w-full text-center">
        {content.length > 5 && numberOfContent < content.length ? (
          <button
            onClick={toggleLoadMore}
            className="rounded-full  px-5 py-2.5 mb-2 bg-pattern-blue dark:bg-turquoise text-customFontThree text-allPorts dark:text-black font-semibold"
          >
            Load More
          </button>
        ) : (
          <button className="rounded-full  px-5 py-2.5 mb-2 bg-pattern-blue dark:bg-turquoise text-customFontThree text-allPorts dark:text-black font-semibold">
            <a
              href="https://www.texelio.com/demo"
              target="_blank"
              rel="noreferrer"
            >
              Book a Demo
            </a>
          </button>
        )}
      </div>
    </div>
  ) : (
    <>
      {loading && (
        <div className="w-full text-center">
          <AlphaCardSkeleton/>
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </>
  );
  return results;
};

export default memo(CompanySpecificInfo);
