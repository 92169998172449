import React, { useState, useRef, memo, useMemo, useEffect } from "react";
import CompanyHeader from "./CompanyHeader";
import { groupBy, uniqueId, uniqBy } from "lodash";
import CustomButton from "../../CustomButton";
import Summary from "../../Summary";
import { revealHiddenSection, toggleSummary } from "./../../../common/api";
import { bodyText } from "../../../common/exportedFunctions";
import { useSelector } from "react-redux";
const Post = ({ articleContent }) => {
  const { translatedTopics } = useSelector((store) => store.app);
  const [summary, setSummary] = useState({
    summaryTitle: "",
    summaryBody: "",
    customButtonType: "",
    customButtonText: "",
  });
  const [showSummary, setShowSummary] = useState(false);
  const hiddenTextRef = useRef([]);
  const {
    image_url: imageUrl,
    source_name: sourceName,
    primary_concept: { name: companyName, id: companyId },
    companies: companiesArray,
    created_ts: releaseDate,
    hidden_key: hiddenArticleKey,
    topics,
    sections,
    headline,
    hidden_source_url: hiddenSourceUrl,
  } = articleContent;
  const filteredTopicsObj = useMemo(() => groupBy(topics, "id"), [topics]);
  const filteredSections = useMemo(
    () =>
      uniqBy(
        sections.map((sectionObj) => ({
          ...sectionObj,
          topic_ids: sectionObj.topic_ids[0],
        })),
        "topic_ids"
      ),
    [sections]
  );

  useEffect(() => {
    //set default ... for each span
    Object.values(hiddenTextRef.current).forEach((span) => {
      if (span)
        span.innerHTML = `<span class='text-gray-600 dark:text-gray-300'>... (see more)</span>`;
    });
  }, []);
  const showSummaryHandler = async (params = null) => {
    if (params) {
      await toggleSummary(params);
    }
    setShowSummary(!showSummary);
  };
  const sectionsArray = useMemo(
    () =>
      filteredSections.slice(0, 3).map((section) => {
        const buttonType = filteredTopicsObj[section.topic_ids][0].type;
        const buttonText =
          translatedTopics.filter(
            (topic) => topic.topic_id === section.topic_ids
          )[0]?.name ?? filteredTopicsObj[section.topic_ids][0].name;
        const unqId = uniqueId("section");
        const sectionSummaryOrder = section.section_orders[0];
        return (
          <div key={unqId} className="p-2 my-1.5 text-black dark:text-white bg-stark-white dark:bg-aluminium rounded-md">
            <div className="w-full flex items-center ">
              <p className="text-red-500 dark:text-red-500 mr-2 font-bold text-customFontTwo">
                {sectionSummaryOrder}{" "}
                {`${sectionSummaryOrder > 1 ? "rows" : "row"}`} down:
              </p>
              {buttonText !== "NONE" && (
                <div className=" flex items-center justify-between ">
                  <CustomButton
                    customButtonProps={{
                      type: buttonType,
                      text: buttonText,
                      isClear: false,
                      category: "topics",
                    }}
                  />
                  {section.has_summary && (
                    <button
                      onClick={() => {
                        showSummaryHandler({
                          buttonType,
                          buttonText,
                          sectionSummaryOrder,
                          hiddenArticleKey,
                          summary,
                          setSummary,
                          companyName,
                        });
                      }}
                      className="underline  dark:text-white  font-bold mx-2 text-customFontSix"
                    >
                      Summarize
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="w-full text-customFontEleven mt-1">
              {section.end_hidden ? (
                <p
                  className="w-full text-clip overflow-hidden cursor-pointer"
                  onClick={() =>
                    revealHiddenSection(
                      section.hidden_text_data,
                      unqId,
                      hiddenTextRef
                    )
                  }
                >
                  {bodyText(section.segments, companyId)}
                  <span ref={(span) => (hiddenTextRef.current[unqId] = span)} />
                </p>
              ) : (
                <p className="w-full ">
                  {bodyText(section.segments, companyId)}
                </p>
              )}
            </div>
          </div>
        );
      }),
    [filteredSections]
  );

  return (
    <div className="w-full pb-2.5  mb-5 bg-linen dark:bg-rhino dark:text-white ">
      <CompanyHeader
        companyInfo={{
          imageUrl,
          sourceName,
          companyName,
          companyId,
          companiesArray,
          releaseDate,
          hiddenSourceUrl,
          headline,
        }}
      />
      {sectionsArray}
      <div className="w-full">
        <Summary
          summaryProps={{
            showSummary,
            summary,
            showSummaryHandler,
            releaseDate,
            sourceName,
          }}
        />
      </div>
    </div>
  );
};

export default memo(Post);
