import React, { useEffect, useRef, useContext } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import InternalNavBar from "./InternalNavBar";
import { contentContext } from "./../common/context";
import { aggregateType } from "../common/exportedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getFeedData } from "./../features/feed/feedSlice";
import { getVisualiticsData } from "./../features/visualitics/visualiticsSlice";
const Feed = () => {
  const { dynamicWidth } = useContext(contentContext);
  const { language, mood, selectedData, media, topics, section } = useSelector(
    (store) => store.app
  );
  const { afterTime } = useSelector((store) => store.visualitics);
  const abortControllerRef = useRef(null);
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const languageArr = language === "all" ? [] : [`${language}`];
  const requestBodyData = {
    topicIds: [],
    companyIds: [],
    indexIds: [],
    sectorCodes: [],
    languages: languageArr,
    sentiment: `${mood}`,
    topicType: `${topics}`,
    sectionType: `${section}`,
  };

  const updateContent = () => {
    const page = location.pathname.split("/");
    const visualiticsPage = page.includes("visualitics");
    const mediaTypes = media === "all" ? ["NEWS", "PR"] : [`${media}`];
    if (visualiticsPage) {
      const [, , categoryName, sectionName] = page;
      const aggregate = aggregateType(categoryName);
      const modifiedRequestBodyData = {
        ...requestBodyData,
        aggregateType: aggregate,
        topNum: 50,
        afterTime,
      };
      dispatch(
        getVisualiticsData({
          sectionName,
          modifiedRequestBodyData,
          selectedData,
          abortControllerRef,
        })
      );
    } else {
      const modifiedRequestBodyData = {
        ...requestBodyData,
        limit: 50,
        freeTextQuery: "",
        mediaTypes,
        sources: [],
        includeSectionOrders: true,
        checkHasSummary: true,
        matchingTypes: [],
      };
      dispatch(
        getFeedData({
          modifiedRequestBodyData,
          selectedData,
          abortControllerRef,
        })
      );
    }
  };
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    updateContent();
    return () => abortControllerRefCopy.current?.abort();
  }, [
    selectedData,
    mood,
    language,
    location,
    afterTime,
    media,
    topics,
    section,
  ]);
  useEffect(() => {
    navigate("/feeditems");
  }, []);
  return (
    <div
      style={{ width: `${dynamicWidth}` }}
      className="mx-auto min-h-screen bg-sazerac dark:bg-nero "
    >
      <InternalNavBar internalNavBarProps={{ abortControllerRef }} />
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default Feed;
