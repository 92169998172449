import React, { useState, memo } from "react";
const Moods = ({ moodsProps }) => {
  const name = moodsProps?.companyInfoData[0]?.name;
  const [selectedBtn, setSelectedBtn] = useState("today");
  const heatMaps = [
    { name: "Total", number: 825, heatMap: "bg-totalHeatMapBg" },
    { name: "News", number: 800, heatMap: "bg-newsHeatMapBg" },
    { name: "Press", number: 25, heatMap: "bg-pressHeatMapBg" },
  ];
  const timeButtons = [
    { name: "today", value: "24h" },
    { name: "last week", value: "7d" },
    { name: "last month", value: "30d" },
  ];
  const filterTime = (btnName) => {
    setSelectedBtn(btnName);
  };
  let renderedContent = <h1>Moods</h1>;
  if (name === "Apple Inc (AAPL)") {
    renderedContent = (
      <div className="bg-inherit w-full py-1 text-black">
        <div className="w-full bg-linen dark:bg-rhino px-2 py-4">
          {heatMaps.map((heatmap) => {
            return (
              <div key={heatmap.name} className="w-full my-2.5">
                <div
                  className={[
                    "w-full h-9 bg-bottom bg-cover bg-no-repeat",
                    heatmap.heatMap,
                  ].join(" ")}
                ></div>
                <p className="my-0.5 text-customFontThree font-semibold dark:text-white">
                  {heatmap.name}: {heatmap.number}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-10/12 mx-auto my-2 rounded-full p-1 bg-linen dark:bg-rhino  font-semibold text-black-russian text-customFontThree grid grid-cols-3 grid-rows-1 items-center ">
          {timeButtons.map((button) => (
            <button
              key={button.value}
              onClick={() => filterTime(button.name)}
              className={[
                "w-full rounded-full  py-1.5 px-9 text-center",
                selectedBtn === button.name
                  ? " bg-onahau dark:bg-turquoise "
                  : " dark:text-white",
              ].join(" ")}
            >
              {button.value}
            </button>
          ))}
        </div>
      </div>
    );
  }
  return renderedContent;
};

export default memo(Moods);
