import React, { useRef, useState, useEffect } from "react";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import PodCastAudioPlayer from "./PodCastAudioPlayer";
import PodCastTextContent from "./PodCastTextContent";
import BeatLoader from "react-spinners/BeatLoader";
import { getEpisodeFromApi } from "./../common/api";
const TranscriptPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const [searchParams, _] = useSearchParams();
  const transcriptUrl = searchParams.get("url")
  const abortControllerRef = useRef(null);
  const audioPlayerRef = useRef();
  const [data, setData] = useState({
    episode: {},
    loading: false,
    message: "",
    error: "",
  });
  const { episode, loading, message, error } = data;
  const getEpisode = () => {
    getEpisodeFromApi({
      data,
      setData,
      abortControllerRef,
      transcriptUrl,
    });
  };

  const content = useMemo(() => {
    return Object.keys(episode);
  }, [episode]);

  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getEpisode();
    return () => abortControllerRefCopy.current?.abort();
  }, [transcriptUrl]);

  const results = content?.length ? (
    <>
      <div className="sticky top-0 bg-linen w-full md:max-w-md mx-auto py-1 px-2">
        <h1 className=" w-full truncate text-center capitalize text-customFontFourteen font-semibold my-2 underline">
          {episode.title}
        </h1>
        <PodCastAudioPlayer
          AudioPlayerComponentProps={{
            isPlaying,
            setIsPlaying,
            audioUrl: episode.audio_url,
          }}
          ref={audioPlayerRef}
        />
      </div>
      <div className="w-full md:max-w-md mx-auto py-1">
        <PodCastTextContent
          podCastTextContentProps={{
            audioPlayerCopy: audioPlayerRef,
            setIsPlaying,
            audioText: episode.transcript,
          }}
        />
      </div>
    </>
  ) : (
    <div className="w-full bg-inherit">
      {loading && (
        <div className="w-full text-center">
          <BeatLoader color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );

  return (
    <div className="min-h-screen w-full bg-linen  p-2 text-black">
      {results}
    </div>
  );
};

export default TranscriptPlayer;
