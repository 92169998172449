import React from "react";
import { NavLink } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import { FiTrendingUp } from "react-icons/fi";
const FeedMenu = () => {
  const Links = [
    { title: "visualitics", image: <FiTrendingUp className="w-full" /> },
    { title: "feeditems", image: <CgMenu className="w-full" /> },
  ];

  return (
    <div className="bg-linen dark:bg-rhino grid grid-cols-2 grid-rows-1 p-0.5 rounded-full  h-9">
      {Links.map((linkItem) => (
        <NavLink
          key={linkItem.title}
          to={linkItem.title}
          className={({ isActive }) => {
            const linkClasses = [
              "rounded-full flex justify-center  text-allPorts  items-center py-eightpoint px-2 outline-none ",
            ];
            if (isActive) {
              linkClasses.push("bg-onahau dark:bg-turquoise dark:text-black");
            } else {
              linkClasses.push("dark:text-white");
            }
            return linkClasses.join(" ");
          }}
        >
          <div className="h-5 flex items-center justify-center ">
            {linkItem.image}
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default FeedMenu;
