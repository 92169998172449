import React from "react";
import CheckedIcon from "../../assets/checkedicon.svg";
import UncheckedIcon from "../../assets/uncheckedicon.svg";
import Select from "react-select";
import { FaChevronDown } from "react-icons/fa";
import {
  setLanguage,
  setMedia,
  setMode,
  setMood,
  setSection,
  setTopics,
} from "../../features/app/appSlice";
import { useDispatch } from "react-redux";

// remove default select styles
const styleProxy = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

const CustomContainer = (props) => {
  return (
    <div ref={props.innerRef} {...props.innerProps} className="relative w-full">
      {props.children}
    </div>
  );
};
const CustomControl = (props) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className="w-full h-9 flex items-center justify-center bg-linen dark:bg-rhino  rounded-full text-customFontTwo font-semibold  p-0.5"
    >
      {props.children[0]}
    </div>
  );
};
const CustomValueContainer = ({
  innerRef,
  innerProps,
  children,
  selectProps: {
    value: { icon, label, color },
  },
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="relative w-full h-full flex items-center justify-center "
    >
      <div className="opacity-0">{children}</div>
      <div
        className={[
          `${color}`,
          "absolute",
          " inset-0",
          " w-full",
          "grid",
          " grid-rows-1",
          "grid-cols-selectValue",
          "gap-x-0.5",
          "grid-flow-col",
          "items-center",
          "font-semibold",
        ].join(" ")}
      >
        {icon !== "none" && (
          <span className="w-4 justify-self-end text-center ">
            <img className="w-full" src={icon} alt={label} />
          </span>
        )}
        {icon !== "none" ? (
          <span className="flex items-center justify-center self-center text-center">
            {label}
          </span>
        ) : (
          <span className="flex items-center justify-center col-span-2">
            {label}
          </span>
        )}
        <div className="  text-black dark:text-white">
          <FaChevronDown />
        </div>
      </div>
    </div>
  );
};
const CustomMenu = (props) => {
  const englishLabel = props.options[0].label === "Eng";
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className={[
        " mx-auto absolute  z-50  bg-linen dark:bg-rhino  pt-1.5 pl-1.5 pr-threePoint pb-1 ",
        englishLabel ? "w-dropdownMenuWidth" : "w-32",
      ].join(" ")}
    >
      {props.children}
    </div>
  );
};
const formatOptionLabel = (
  { label, icon, color, value, disabled = false },
  { selectValue: [{ value: selectedValue }] }
) => {
  return (
    <div
      className={[
        `${color}`,
        "w-full",
        "grid",
        "grid-rows-1",
        "grid-cols-dropDown",
        "gap-x-1.5",
        "grid-flow-col",
        " items-center",
        " mb-1.5",
        "font-semibold",
      ].join(" ")}
    >
      {icon !== "none" && (
        <span
          className={["w-4 justify-self-start", disabled && "opacity-50"].join(
            " "
          )}
        >
          <img className="w-full" src={icon} alt={label} />
        </span>
      )}
      {icon !== "none" ? (
        <span
          className={["justify-self-start", disabled && "opacity-50"].join(" ")}
        >
          {label}
        </span>
      ) : (
        <span className={["col-span-2", disabled && "opacity-50"].join(" ")}>
          {label}
        </span>
      )}
      <span
        className={["w-3 justify-self-end ", disabled && "opacity-50"].join(
          " "
        )}
      >
        {value === selectedValue ? (
          <img className="w-full" src={CheckedIcon} alt="Checked Icon" />
        ) : (
          <img className="w-full" src={UncheckedIcon} alt="Unchecked Icon" />
        )}
      </span>
    </div>
  );
};
const CustomSelectButton = ({ options }) => {
  const dispatch = useDispatch();
  const handleChange = (selected) => {
    const { category, value } = selected;
    switch (category) {
      case "mood":
        dispatch(setMood(value));
        break;
      case "language":
        dispatch(setLanguage(value));
        break;
      case "mode":
        dispatch(setMode(value));
        break;
      case "topics":
        dispatch(setTopics(value));
        break;
      case "media":
        dispatch(setMedia(value));
        break;
      case "section":
        dispatch(setSection(value));
        break;

      default:
        break;
    }
  };
  const handleDisabledOption = (option) => {
    return option.disabled;
  };
  return (
    <Select
      options={options}
      styles={styleProxy}
      className="text-customFontTwo"
      isSearchable={false}
      defaultValue={options[0]}
      onChange={handleChange}
      placeholder={false}
      formatOptionLabel={formatOptionLabel}
      isOptionDisabled={handleDisabledOption}
      components={{
        Container: CustomContainer,
        Control: CustomControl,
        ValueContainer: CustomValueContainer,
        Menu: CustomMenu,
      }}
    />
  );
};

export default CustomSelectButton;
