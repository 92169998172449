import React, { memo, useMemo, useRef, useEffect, useState } from "react";
import { groupBy, uniqueId } from "lodash";
import { uniqBy } from "lodash";
import { toggleSummary } from "./../../../common/api";
import ShrinkedContent from "./ShrinkedContent";
import ExpandedContent from "./ExpandedContent";
import Summary from "./../../Summary";
import { useSelector } from "react-redux";

const IndividualStock = ({ individualStockProps: { stock } }) => {
  const { translatedTopics } = useSelector((store) => store.app);
  const {
    image_url: imageUrl,
    source_name: sourceName,
    primary_concept: { name: companyName, id: companyId },
    created_ts: releaseDate,
    hidden_key: hiddenArticleKey,
    topics,
    sections,
    hidden_source_url: hiddenSourceUrl,
  } = stock;
  const [summary, setSummary] = useState({
    summaryTitle: "",
    summaryBody: "",
    customButtonType: "",
    customButtonText: "",
  });
  const [showSummary, setShowSummary] = useState(false);
  const dynamicDivRef = useRef([]);
  const hiddenTextRef = useRef([]);
  const ref = useRef({ dynamicDivRef, hiddenTextRef });

  const filteredTopicsObj = useMemo(() => groupBy(topics, "id"), [topics]);
  const filteredSections = useMemo(
    () =>
      uniqBy(
        sections.map((sectionObj) => ({
          ...sectionObj,
          topic_ids: sectionObj.topic_ids[0],
        })),
        "topic_ids"
      ),
    [sections]
  );

  const showSummaryHandler = async (params = null) => {
    if (params) {
      await toggleSummary(params);
    }
    setShowSummary(!showSummary);
  };

  const sectionsArray = useMemo(
    () =>
      filteredSections.slice(0, 3).map((section) => {
        const topicId = filteredTopicsObj[section.topic_ids][0];
        const buttonType = topicId.type;
        const buttonText =
          translatedTopics.filter(
            (topic) => topic.topic_id === section.topic_ids
          )[0]?.name ?? filteredTopicsObj[section.topic_ids][0].name;
        const unqId = uniqueId("reveal");
        const containerId = uniqueId("container");
        const shrinkedId = uniqueId("shrinked");
        const expandedId = uniqueId("expanded");
        const sectionSummaryOrder = section.section_orders[0];
        return (
          <div key={containerId} className="w-full">
            <ShrinkedContent
              ref={ref}
              shrinkedContentProps={{
                releaseDate,
                shrinkedId,
                expandedId,
                section,
                companyId,
              }}
            />
            <ExpandedContent
              ref={ref}
              expandedContentProps={{
                releaseDate,
                expandedId,
                section,
                unqId,
                companyId,
                buttonType,
                hiddenSourceUrl,
                imageUrl,
                sourceName,
                showSummaryHandler,
                sectionSummaryOrder,
                hiddenArticleKey,
                summary,
                setSummary,
                companyName,
                buttonText,
              }}
            />
          </div>
        );
      }),
    [filteredSections]
  );
  useEffect(() => {
    //set default ... for each span
    Object.values(hiddenTextRef.current).forEach((span) => {
      if (span)  span.innerHTML = `<span class='text-gray-600'>... (see more)</span>`;
    });
  }, []);

  return (
    <div className="w-full">
      <div className="w-full">{sectionsArray}</div>
      <div className="w-full">
        <Summary
          summaryProps={{
            showSummary,
            summary,
            showSummaryHandler,
            sourceName,
            releaseDate,
          }}
        />
      </div>
    </div>
  );
};

export default memo(IndividualStock);
