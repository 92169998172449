import React from "react";
import Modal from "react-modal";
import ReactTimeAgo from "react-time-ago";
import { AiOutlineClose } from "react-icons/ai";
import { useDisableBodyScroll } from "./../common/hooks";
Modal.setAppElement("#root");
const Summary = ({ summaryProps }) => {
  const {
    showSummary,
    summary: { summaryTitle, summaryBody },
    showSummaryHandler,
    releaseDate,
    sourceName,
  } = summaryProps;
  // prevent scroll if show summary is true
  useDisableBodyScroll(showSummary);
  const summaryComponent = (modelProps) => {
    return (
      <div {...modelProps}>
        <div className="w-full  p-2.5 flex flex-col items-center justify-center">
          <div
            onClick={() => showSummaryHandler()}
            className="h-full w-full  bg-black/60"
          ></div>
          <div className="absolute bg-linen dark:bg-black w-summaryWidth  p-2.5">
            <div className="flex items-center justify-between mb-1">
              <h1 className="text-customFontFour dark:text-white font-semibold capitalize">
                {summaryTitle}
              </h1>
              <button onClick={() => showSummaryHandler()}>
                <AiOutlineClose className="text-black text-2xl" />
              </button>
            </div>
            <p className="text-customFontEleven dark:text-white w-full mb-1 ">
              {summaryBody}
            </p>
            <hr className="w-full border border-black/5 dark:border-white/5 mb-1" />
            <div className="flex items-center justify-between w-full text-black-russian dark:text-white">
              <p className="text-customFontNine">
                <span className="mr-1">
                  <ReactTimeAgo
                    date={releaseDate * 1000}
                    locale="en-US"
                    timeStyle="round-minute"
                  />
                </span>
                <span className="mr-1">|</span>
                <span className="mr-1">{sourceName}</span>
              </p>
              <p className="text-customFontFive">Summarized by Texelio ©</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={showSummary}
      onRequestClose={() => showSummaryHandler()}
      contentElement={summaryComponent}
      className="p-0"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
        },
      }}
    />
  );
};

export default Summary;
