import React, { useEffect, useState } from "react";
import { groupBy, get } from "lodash";
import CustomButton from "./CustomButton";
import { useSelector } from "react-redux";
const SelectedItems = () => {
  const [data, setData] = useState([]);
  const { selectedData, translatedTopics } = useSelector((store) => store.app);
  const { feed } = useSelector((store) => store);
  const { visualitics } = useSelector((store) => store);
  const handleSelectedData = () => {
    let index = 0;
    const dataObj = groupBy(selectedData, (element) =>
      get(element, "category", "keywords")
    );
    const items = Object.keys(dataObj)
      .sort()
      .map((dataGroup) => {
        return (
          dataObj[dataGroup].length && (
            <div
              key={dataGroup}
              className="w-full mb-2 grid grid-rows-1 gap-x-1 grid-cols-customButton grid-flow-col items-center "
            >
              <h1 className="capitalize text-customFontThree text-black-russian dark:text-white">
                {dataGroup}:
              </h1>
              <div className="flex items-center flex-wrap">
                {dataObj[dataGroup].map((item) => {
                  const { name, type, category, id } = item;
                  let textName = name;
                  if (category === "topics") {
                    const filteredTopic = translatedTopics.filter(
                      (topic) => topic.topic_id === id
                    )[0];
                    textName = filteredTopic.name ?? name;
                  }
                  const itemData = (
                    <CustomButton
                      key={index}
                      customButtonProps={{
                        text: textName,
                        type,
                        category,
                        id,
                        isClear: true,
                      }}
                    />
                  );
                  index += 1;
                  return itemData;
                })}
              </div>
            </div>
          )
        );
      });
    setData(items);
  };
  useEffect(() => {
    handleSelectedData();
  }, [selectedData, feed, visualitics]);
  return <div className="w-full bg-inherit">{data}</div>;
};

export default SelectedItems;
