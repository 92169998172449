import React from "react";
import { uniqueId } from "lodash";
import Skeleton from "react-loading-skeleton";

const skeletons = [6, 5, 4, 3, 2, 1.5];
const VisualiticsSkeleton = () => {
  return (
    <div className="w-full">
      {skeletons.map((number) => {
        const unqid = uniqueId("trending-bars");
        const percentageValue = (number / 6) * 100;
        return (
          <div key={unqid} className="w-full my-2">
            <Skeleton className="w-20 h-3 roundend-none my-0-5 " />
            <Skeleton
              width={`${percentageValue}%`}
              className=" h-4 roundend-none"
            />
          </div>
        );
      })}
    </div>
  );
};

export default VisualiticsSkeleton;
