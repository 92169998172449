import React, { memo } from "react";
import CustomSelectButton from "./CustomSelectButton/CustomSelectButton";
import { uniqueId } from "lodash";

const FinanceNavigation = () => {
  const dropDowns = [
    [
      {
        label: "All Topics",
        value: "all",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "topics",
      },
      {
        label: "Finance",
        value: "finance",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "topics",
      },
      {
        label: "ESG",
        value: "esg",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "topics",
      },
      {
        label: "Strategy",
        value: "strategy",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "topics",
        disabled: true,
      },
      {
        label: "Law",
        value: "law",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "topics",
        disabled: true,
      },
    ],

    [
      {
        label: "All Media",
        value: "all",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "media",
      },
      {
        label: "News",
        value: "news",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "media",
      },
      {
        label: "Press Release",
        value: "pr",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "media",
      },
      {
        label: "Earning Calls",
        value: "earningcalls",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "media",
        disabled: true,
      },
    ],

    [
      {
        label: "All Sections",
        value: "all",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "section",
      },
      {
        label: "Title",
        value: "title",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "section",
      },
      {
        label: "Preface",
        value: "preface",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "section",
      },
      {
        label: "Body",
        value: "body",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "section",
      },
      {
        label: "About",
        value: "about",
        icon: "none",
        color: "text-black-russian dark:text-white",
        category: "section",
      },
    ],
  ];

  return (
    <div className="w-10/12 mx-auto grid grid-cols-3 grid-rows-1 gap-x-0.5 items-center  my-1.5  p-1">
      {dropDowns.map((dropDown) => {
        const unqId = uniqueId("dropdown");
        return <CustomSelectButton key={unqId} options={dropDown} />;
      })}
    </div>
  );
};

export default memo(FinanceNavigation);
