import React, { useContext } from "react";
import { visualiticsContext } from "../../common/context";

const VisualiticsLatestTrendingCategory = () => {
  const { visualiticsBtns, setVisualiticsBtns } =
    useContext(visualiticsContext);
  const { trendyBtn: selectedBtn } = visualiticsBtns;
  const categories = [{ name: "latest" }, { name: "trending" }];
  const changeCategory = (btnName) => {
    setVisualiticsBtns({ ...visualiticsBtns, trendyBtn: btnName });
  };
  return (
    <div className="w-full text-black text-customFontTwo font-semibold bg-linen dark:bg-rhino  rounded-full flex justify-between items-center   p-1">
      {categories.map((button) => (
        <button
          key={button.name}
          onClick={() => changeCategory(button.name)}
          className={[
            "w-full text-center text-black rounded-full py-2 px-2.5 capitalize whitespace-nowrap",
            selectedBtn === button.name ? "bg-onahau dark:bg-turquoise" : "dark:text-white",
          ].join(" ")}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
};

export default VisualiticsLatestTrendingCategory;
