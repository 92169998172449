import React, { useRef, useState, useContext } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { get, groupBy } from "lodash";
import {
  AsyncTypeahead,
  Hint,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import {
  filterSelectedData,
  handleSearchItems,
} from "./../common/exportedFunctions";
import { AiOutlineSearch } from "react-icons/ai";
import { BiInfoCircle } from "react-icons/bi";
import CompanyInfo from "./CompanyInfo";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedData } from "../features/app/appSlice";
const SearchBar = ({ searchBarProps }) => {
  const { selectedData } = useSelector((store) => store.app);
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState([]);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const { abortControllerRef, sectionName } = searchBarProps;
  const typeAheadRef = useRef();
  const {
    setTriggerRef,
    getArrowProps,
    visible,
    getTooltipProps,
    setTooltipRef,
  } = usePopperTooltip({
    placement: "bottom-end",
    trigger: "click",
    interactive: true,
  });
  const infoContent = [
    { category: "Keywords", description: "type any keyword such as “EBITDA" },
    { category: "Stocks", description: "Tesla, AAPL, $COIN" },
    {
      category: "Commodities",
      description: "coffee, lithium, oil, beans, lumber, iron ",
    },
    { category: "Indices", description: "CAC40, OMX30, S&P500" },
    {
      category: "Topics",
      description: "buyback, dividend, animal rights, water waste",
    },
  ];
  const toggleCompanyInfo = (params = null) => {
    if (params) {
      const { result } = params;
      const resultArr = [result];
      const filteredData = filterSelectedData(resultArr, []);
      setCompanyInfoData([...filteredData]);
    }
    setShowCompanyInfo(!showCompanyInfo);
  };
  const customInput = (props) => {
    const { inputRef, referenceElementRef, ...otherInputProps } = props;
    return (
      <Hint>
        <input
          {...otherInputProps}
          ref={(input) => {
            inputRef(input);
            referenceElementRef(input);
          }}
          className={
            "w-full outline-none  text-black bg-linen dark:bg-rhino dark:text-white"
          }
          placeholder={sectionName ? `Edit ${sectionName}` : "Find anything"}
        ></input>
      </Hint>
    );
  };
  const customMenu = (results, menuProps, state) => {
    // removing non-required props
    const {
      newSelectionPrefix,
      paginationText,
      renderMenuItemChildren,
      ...otherMenuProps
    } = menuProps;
    let index = 0;
    const resultsObj = groupBy(results, (element) =>
      get(element, "category", "keywords")
    );
    const items = Object.keys(resultsObj).map((categoryName) => {
      return (
        <div key={categoryName} className="my-2 w-full  flex flex-col">
          <h1 className="my-px w-full font-bold capitalize">{categoryName}:</h1>
          {resultsObj[categoryName].slice(0, 3).map((result) => {
            const item = (
              <div
                key={index}
                className="w-full grid grid-cols-optionLabel grid-rows-1 grid-flow-col gap-x-1 items-center my-1"
              >
                <MenuItem
                  className={
                    "grid grid-cols-menuItem grid-rows-1 grid-flow-col gap-x-1 items-center pl-1  w-full hover:bg-gray-500/10 dark:hover:bg-gray-500"
                  }
                  option={result}
                  position={index}
                >
                  <div className="w-5 mr-0.5">
                    {result.logo && (
                      <img className="w-full" src={result.logo} alt="Logo" />
                    )}
                  </div>
                  <div className="truncate">
                    <Highlighter search={state.text}>{result.name}</Highlighter>
                  </div>
                </MenuItem>
                {categoryName === "companies" && (
                  <button onClick={() => toggleCompanyInfo({ result })}>
                    <BiInfoCircle className={"text-2xl dark:text-white"} />
                  </button>
                )}
              </div>
            );
            index += 1;
            return item;
          })}
        </div>
      );
    });
    return (
      <Menu
        {...otherMenuProps}
        className={
          "w-full top-8 text-black  px-2 rounded-sm bg-linen dark:bg-rhino dark:text-white"
        }
        style={{ position: "absolute" }}
      >
        {items}
      </Menu>
    );
  };

  const handleSearch = async (searchWord) => {
    const newArrObj = await handleSearchItems(searchWord, abortControllerRef);
    setApiData(newArrObj);
  };
  const handleSelectedItems = (selected) => {
    const filteredData = filterSelectedData(selected, selectedData);
    dispatch(setSelectedData(filteredData));
    // clear selected value
    typeAheadRef.current.clear();
  };
  return (
    <div
      className={
        " w-full text-customFontThree  text-black dark:text-white  rounded-full grid grid-rows-1 grid-cols-searchBar gap-x-1.5 items-center  my-1.5  py-2 px-2.5 bg-linen dark:bg-rhino"
      }
    >
      <div>
        <AiOutlineSearch className={" text-2xl "} />
      </div>
      <AsyncTypeahead
        ref={typeAheadRef}
        id="searchbar"
        labelKey="name"
        isLoading={false}
        options={apiData}
        className="w-full bg-inherit "
        renderInput={customInput}
        renderMenu={customMenu}
        minLength={2}
        onSearch={handleSearch}
        onChange={(selected) => handleSelectedItems(selected)}
        allowNew={true}
      />
      <button ref={setTriggerRef}>
        <BiInfoCircle className={" text-2xl dark:text-white"} />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container w-96 bg-inherit p-2.5",
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow bg-inherit" })} />
          <div className="w-full dark:text-white">
            <h1 className="w-full text-customFontFour font-bold ">
              Find Anything
            </h1>
            <div className="w-full my-1.5">
              {infoContent.map((info) => {
                const { category, description } = info;
                return (
                  <p
                    key={category}
                    className="w-full text-customFontThree flex items-center justify-start my-1"
                  >
                    <span className="font-bold mr-2">{category}:</span>
                    <span>{description}</span>
                  </p>
                );
              })}
            </div>
            <hr className="border-0.5 border-black dark:border-east-bay w-full" />
            <div className="mt-1 w-full flex items-center justify-between text-customFontSix">
              <p>
                Missing something?{" "}
                <a
                  href="https://www.texelio.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-summer-sky underline"
                >
                  Contact us.
                </a>
              </p>
              <p>Powered by Texelio ©</p>
            </div>
          </div>
        </div>
      )}
      <CompanyInfo
        companyInfoProps={{
          companyInfoData,
          showCompanyInfo,
          toggleCompanyInfo,
        }}
      />
    </div>
  );
};

export default SearchBar;
