import React from "react";
import { uniqueId } from "lodash";
import Skeleton from "react-loading-skeleton";

const AlphaCardSkeleton = () => {
  const cards = Array(5)
    .fill(0)
    .map((_) => {
      const unqId = uniqueId("alphamode-skeleton");
      return (
        <div
          key={unqId}
          className="w-full bg-linen-shade-one dark:bg-rhino my-2 p-1"
        >
          <div className="w-full flex items-center justify-center ">
            <hr className="w-full" />
            <Skeleton className="w-16 h-4 mx-2 roundend-none " />
            <hr className="w-full" />
          </div>
          <div className="w-full grid grid-cols-optionLabel grid-rows-1 grid-flow-col gap-x-1">
            <Skeleton count={1.5} className="w-full h-2 roundend-none " />
            <Skeleton className="w-16 h-3 mx-2 roundend-none " />
          </div>
        </div>
      );
    });
  return <div className="w-full">{cards}</div>;
};

export default AlphaCardSkeleton;
