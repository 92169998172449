import React, { memo, useState } from "react";

import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { useDisableBodyScroll } from "./../common/hooks";
import CompanySpecificInfo from "./CompanySpecificInfo";
import Earnings from "./Earnings";
import Moods from "./Moods";
Modal.setAppElement("#root");
const CompanyInfo = ({ companyInfoProps }) => {
  const { companyInfoData, showCompanyInfo, toggleCompanyInfo } =
    companyInfoProps;
  const [apiCompanyInfo, setApiCompanyInfo] = useState({
    searchResults: [],
    loading: true,
    message: "",
    error: "",
  });
  const name = companyInfoData[0]?.name;
  const logo = companyInfoData[0]?.logo;
  const [selectedBtn, setSelectedBtn] = useState("Media");
  const categories = [
    { name: "Total" },
    { name: "News" },
    { name: "Press" },
    { name: "Media" },
    { name: "Moods" },
  ];
  const changeCategory = (btnName) => {
    setSelectedBtn(btnName);
  };
  let renderedContent;
  if (selectedBtn === "Total")
    renderedContent = (
      <CompanySpecificInfo
        companySpecificInfoProps={{
          companyInfoData,
          apiCompanyInfo,
          setApiCompanyInfo,
        }}
      />
    );
  if (selectedBtn === "News") renderedContent = <h1>News</h1>;
  if (selectedBtn === "Press") renderedContent = <h1>Press</h1>;
  if (selectedBtn === "Media")
    renderedContent = <Earnings earningsProps={{ companyInfoData }} />;
  if (selectedBtn === "Moods")
    renderedContent = <Moods moodsProps={{ companyInfoData }} />;
  // prevent scroll if show summary is true
  useDisableBodyScroll(showCompanyInfo);
  const companyInfoContainer = (modelProps) => {
    return (
      <div {...modelProps} className="h-max outline-0 ">
        <div className="w-companyInfoWidth  text-black bg-sazerac dark:bg-aluminium rounded-md">
          <div className="w-full flex items-center justify-between p-2 bg-linen  dark:bg-rhino">
            <div className="w-7 mr-0.5">
              {logo && <img className="w-ful" src={logo} alt="Logo" />}
            </div>
            <h1 className="max-w-70 truncate capitalize py-2 text-customFontFourteen font-bold dark:text-white">
              {name}
            </h1>
            <button onClick={toggleCompanyInfo}>
              <AiOutlineClose className={"text-2xl dark:text-white"} />
            </button>
          </div>
          <div className="w-full px-1.5 pt-5 pb-1.5">
            <div className="w-full text-customFontTwo font-bold bg-linen dark:bg-rhino  rounded-full flex justify-between items-center p-1">
              {categories.map((button) => (
                <button
                  key={button.name}
                  onClick={() => changeCategory(button.name)}
                  className={[
                    "w-full text-center rounded-full py-2 px-1.5 capitalize whitespace-nowrap",
                    selectedBtn === button.name
                      ? "bg-onahau dark:bg-turquoise"
                      : "dark:text-white",
                  ].join(" ")}
                >
                  {button.name}
                </button>
              ))}
            </div>
          </div>
          <div className="w-full  max-h-companyInfoHeight overflow-auto scrollbar-hide px-1.5 dark:text-white">
            {renderedContent}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={showCompanyInfo}
      onRequestClose={toggleCompanyInfo}
      contentElement={companyInfoContainer}
      className="px-0"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "120px",
          zIndex: 60,
        },
      }}
    />
  );
};

export default memo(CompanyInfo);
