import React, { memo, useMemo, useState } from "react";
import { uniqueId } from "lodash";
import { FaChevronDown } from "react-icons/fa";
import IndividualStock from "./IndividualStock";
const AlphaContent = ({ alphaComponentProps: { stockInfo, id } }) => {
  const [numberOfContent, setNumberOfContent] = useState(3);
  const groupInfo = useMemo(() => {
    return stockInfo
      .map((stock) => stock.companies)
      .flat()
      .filter((company) => company.id === id)[0];
  }, [id]);
  const exchangeSymbol = groupInfo?.exchange_symbol;
  const ticker = exchangeSymbol?.split(":")[1] ?? null;
  const stockName = `${ticker}`;
  const contentSections = useMemo(() => {
    return stockInfo.map((stock) => {
      const unqId = uniqueId("stockInfo");
      return <IndividualStock key={unqId} individualStockProps={{ stock }} />;
    });
  }, [stockInfo]);
  const toggleLoadMore = () => {
    setNumberOfContent((prevNumber) => prevNumber + 3);
  };
  return (
    <div className="w-full bg-linen mb-2 px-1.5 py-1 text-black-russian dark:bg-black dark:text-white">
      <div className="w-full flex items-center justify-between">
        <hr className="w-full border border-sazerac  dark:border-ship-cove/50" />
        <h1 className="whitespace-nowrap text-center text-customFontFourteen m-0.5 font-bold ">
          {stockName}
        </h1>
        <hr className="w-full border border-sazerac dark:border-ship-cove/50" />
      </div>
      <div className="w-full">
        <div className="w-full">
          {contentSections.slice(0, numberOfContent)}
        </div>
        {contentSections.length > 3 && (
          <div className="w-full flex items-center justify-center">
            <hr className="w-full border border-sazerac  dark:border-ship-cove/50" />
            {contentSections.length > 3 &&
              numberOfContent < contentSections.length && (
                <button
                  onClick={toggleLoadMore}
                  className="flex flex-nowrap items-center justify-center mx-0.5 text-customFontFourteen font-semibold"
                >
                  +{contentSections.length - numberOfContent}
                  <FaChevronDown className="ml-1" />
                </button>
              )}
            <hr className="w-full border border-sazerac dark:border-ship-cove/50"  />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AlphaContent);
