import React from "react";
import PositiveIcon from "../../assets/positiveicon.svg";
import NeutralIcon from "../../assets/neutralicon.svg";
import NegativeIcon from "../../assets/negativeicon.svg";
import CustomSelectButton from './../CustomSelectButton/CustomSelectButton';
const moods = [
  {
    label: "All Moods",
    value: "all",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "mood",
  },
  {
    label: "Positive",
    value: "positive",
    icon: PositiveIcon,
    color: "text-pastel-green",
    category: "mood",
  },
  {
    label: "Neutral",
    value: "neutral",
    icon: NeutralIcon,
    color: "text-ship-cove",
    category: "mood",
  },

  {
    label: "Negative",
    value: "negative",
    icon: NegativeIcon,
    color: "text-red-orange",
    category: "mood",
  },
];

const ContentMood = () => {
  return <CustomSelectButton options={moods} />;
};

export default ContentMood;
