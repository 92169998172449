import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { getPodCastsFromApi } from "./../common/api";
import { uniqueId } from "lodash";
import BeatLoader from "react-spinners/BeatLoader";

const PodCasts = () => {
  const [data, setData] = useState({
    podcasts: [],
    loading: false,
    message: "",
    error: "",
  });
  const { podcasts, loading, message, error } = data;
  const abortControllerRef = useRef(null);
  const getPodcasts = () => {
    getPodCastsFromApi({
      data,
      setData,
      abortControllerRef,
    });
  };
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getPodcasts();
    return () => abortControllerRefCopy.current?.abort();
  }, []);

  const content = useMemo(() => {
    return podcasts.map((podcast) => {
      const unqId = uniqueId("podcast");
      return (
        <li key={unqId} className=" cursor-pointer my-2 font-bold">
          <Link to={`${podcast.id}`}>{podcast.title}</Link>
        </li>
      );
    });
  }, [podcasts]);
  const results = content?.length ? (
    <div className="w-full bg-inherit">
      <ol className="w-full my-4 list-decimal list-inside">{content}</ol>
    </div>
  ) : (
    <div className="w-full bg-inherit">
      {loading && (
        <div className="w-full text-center">
          <BeatLoader color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );
  return (
    <div className="w-full min-h-screen bg-linen p-2">
      <div className="w-full md:max-w-md mx-auto">
        <h1 className="text-center my-4 underline font-bold">
          Carnegie Podcasts
        </h1>
        {results}
      </div>
    </div>
  );
};

export default PodCasts;
