import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./features/app/appSlice";
import feedReducer from "./features/feed/feedSlice";
import visualiticsReducer from "./features/visualitics/visualiticsSlice";
export const store = configureStore({
  reducer: {
    app: appReducer,
    feed: feedReducer,
    visualitics: visualiticsReducer,
  },
});
